import { createAction } from '@reduxjs/toolkit';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import { useQuestionnaireForReturnMethod } from '../api/questionnaire/hooks';
import {
  ArticlesWithIncorrectUnit,
  articlesWithIncorrectUnit,
  removePoP,
  SidebarPageKey,
  showOTCArticleSidebar,
  timeWindowSet,
  removeSelectedArticlesPop,
  removeArticle,
  removeBlindReturnArticle,
} from './extraReducersActions';
import { PoPSelectedItem } from '../OTCSlice/reducerUtils';

// we export the actions so we can use them in the extraReducers, components should use the hooks
export const returnMethodSaved = createAction<{ hasQuestionnaire: boolean } | undefined>('returnMethodSaved');
export const questionnaireSaved = createAction('questionnaireSaved');
export const setActionsSidebarPop = createAction<{ popId: string | null }>('setActionsSidebarPop');

export { timeWindowSet };

export const useReturnMethodSaved = () => {
  const dispatch = useLamnaDispatch();
  const returnOption = useLamnaSelector(
    (state) => state.selectedReturnMethod.draft.selectedReturnMethod?.id,
  );
  const questionnaire = useQuestionnaireForReturnMethod(returnOption);
  const hasQuestionnaire = Boolean(questionnaire);

  return () => {
    dispatch(returnMethodSaved({ hasQuestionnaire }));
  };
};

export const useQuestionnaireSaved = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(questionnaireSaved());
  };
};

export const useTimeWindowSet = () => {
  const dispatch = useLamnaDispatch();
  const hasReturnMethod = useLamnaSelector(
    (state) => state.selectedReturnMethod.selectedReturnMethod?.id,
  );
  return (timeWindowHasChangedInViewMode: boolean) => {
    dispatch(timeWindowSet({
      hasCommittedMethod: Boolean(hasReturnMethod),
      timeWindowHasChangedInViewMode,
    }));
  };
};

export const useSetArticlesWithIncorrectUnit = () => {
  const dispatch = useLamnaDispatch();
  return (articles: ArticlesWithIncorrectUnit) => {
    dispatch(articlesWithIncorrectUnit(articles));
  };
};

export const useShowOTCArticleSidebar = () => {
  const dispatch = useLamnaDispatch();
  return (article: PoPSelectedItem, sidebarPage: SidebarPageKey) => {
    dispatch(showOTCArticleSidebar({ article, sidebarPage }));
  };
};

export const useSetActionsSidebarPop = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string | null) => {
    dispatch(setActionsSidebarPop({ popId }));
  };
};

export const useRemovePoP = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(removePoP());
  };
};

export const useRemoveSelectedArticlesPop = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(removeSelectedArticlesPop());
  };
};

export const useRemoveArticle = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(removeArticle());
  };
};

export const useRemoveBlindReturnArticle = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(removeBlindReturnArticle());
  };
};
