import { useTranslation } from 'react-i18next';
import { SidebarHeader } from '../SidebarHeader';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { useGetReturnReference } from '../../../store/features/appStateSlice/appStateSlice';

export function QuestionnaireHeader() {
  const { t } = useTranslation();
  const returnReference = useGetReturnReference();
  const handleBack = returnReference ? undefined : useGoToSidebarPage('return-method');

  return (
    <SidebarHeader
      backBtnClick={handleBack}
      ariaBackBtnTxt={t('questionnaire-sidebar.backBtn')}
      title={t('questionnaire-sidebar.title')}
    />
  );
}
