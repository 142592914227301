import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import { useClosePrompt } from '../../store/features/prompt/promptSlice';

export default function CloseOnlyFooter() {
  const { t } = useTranslation();
  const closePrompt = useClosePrompt();
  return (
    <Button
      className="whitespace-nowrap"
      onClick={closePrompt}
      text={t('commonly-reused.close')}
      type="secondary"
    />
  );
}

CloseOnlyFooter.displayName = 'Button';
