import InlineMessage from '@ingka/inline-message';
import { ModalBody } from '@ingka/modal';
import Search, { SearchProps } from '@ingka/search';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPops } from '../../../store/features/OTCSlice/OTCSlice';
import { useGetReturnAuthorizationProducts } from '../../../hooks/useGetReturnAuthorizationProducts.js';

export function OTCSearchBody() {
  const popsInStore = useGetPops();
  const [validationError, setValidationError] = useState('');
  const { t } = useTranslation();
  const getReturnAuthorizationProducts = useGetReturnAuthorizationProducts();

  const clearValidationErrorOnInputChange = () => {
    if (validationError) {
      setValidationError('');
    }
  };

  const onSearch: SearchProps['onSearch'] = (_, data) => {
    if (typeof data?.value === 'string') {
      const searchString: string = data.value;
      const popAlreadyAdded = popsInStore
        .some((statePop) => statePop.id === searchString);

      if (popAlreadyAdded) {
        setValidationError(t('otc-search-sidebar.popAlreadyAdded'));
        return;
      }

      getReturnAuthorizationProducts(searchString);
    }
  };

  return (
    <ModalBody>
      <Search
        ariaLabel={t('otc-search-sidebar.ariaLabel')}
        clearBtnText={t('otc-search-sidebar.clearBtnText')}
        id="otc-search-sidebar-input"
        onChange={clearValidationErrorOnInputChange}
        onClear={clearValidationErrorOnInputChange}
        onSearch={onSearch}
        placeholder={t('otc-search-sidebar.searchPlaceholder')}
        submitBtnText={t('otc-search-sidebar.submitBtnText')}
      />
      {!!validationError && (
        <InlineMessage
          body={validationError}
          className="mt-4"
          ssrIcon={informationCircle}
          variant="negative"
        />
      )}
    </ModalBody>
  );
}
