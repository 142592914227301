import { useLazyGetReturnAuthorizationProductsQuery } from '../store/features/api/apiSlice';
import { useMarketLocale } from './useMarketLocale';

export const useGetReturnAuthorizationProducts = () => {
  const [getReturnAuthorizationProducts] = useLazyGetReturnAuthorizationProductsQuery();
  const { countryCode, languageCode } = useMarketLocale();

  return (purchaseId: string) => getReturnAuthorizationProducts({
    countryCode, languageCode, purchaseId,
  });
};
