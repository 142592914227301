import SkapaToast from '@ingka/toast';
import { useTranslation } from 'react-i18next';
import { useGetToast, useToast } from '../../../store/features/toastSlice/toastSlice';

function Toast() {
  const { t } = useTranslation();
  const { message, isVisible, type } = useGetToast();
  const { hideToast } = useToast();
  const errorStyles = type === 'error' ? '!bg-negative' : '';

  return (
    <SkapaToast
      text={t(message) || message}
      isOpen={isVisible}
      onCloseRequest={hideToast}
      ariaLabelCloseBtn={t('commonly-reused.close')}
      className={errorStyles}
    />
  );
}

export default Toast;
