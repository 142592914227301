import Button from '@ingka/button';
import { t } from 'i18next';
import useHasPickupFailed from '../../../hooks/useHasPickupFailed/useHasPickupFailed';
import { useRescheduleReturn } from '../../../hooks/useRescheduleReturn';
import { useGoToPromptPage } from '../../../store/features/prompt/promptSlice';

function SaveReturnButton() {
  const { isRescheduleLoading, rescheduleReturn } = useRescheduleReturn();
  const pickupFailed = useHasPickupFailed();
  const openPickupFailedRescheduleModal = useGoToPromptPage('failed-pickup-reschedule');
  return (
    <Button
      onClick={pickupFailed ? openPickupFailedRescheduleModal : rescheduleReturn}
      text={t('control-bar.button.saveReturn')}
      className="col-start-2 col-end-3"
      type="emphasised"
      size="small"
      loading={isRescheduleLoading}
      disabled={isRescheduleLoading}
    />
  );
}

export default SaveReturnButton;
