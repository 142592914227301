import { useCreateRoute, useViewRoute } from '../../hooks/useAppRoutes';

export function CreateRouteOnly({ children }: { children: JSX.Element }) {
  const isCreateRoute = useCreateRoute();
  return isCreateRoute ? children : null;
}

export function ViewRouteOnly({ children }: { children: JSX.Element }) {
  const isViewRoute = useViewRoute();
  return isViewRoute ? children : null;
}
