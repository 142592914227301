import { useEffect } from 'react';
import Modal, { Sheets } from '@ingka/modal';
import { useGetSidebarOpen, useGetSidebarPage, useCloseSidebar } from '../../store/features/sidebar/sidebarSlice';
import SidebarRouter from './SidebarRouter';

const useRecaptureFocus = (isSidebarOpen: boolean) => {
  const sheetId = 'lamna-sidebar-sheet';
  useEffect(() => {
    const { activeElement } = document;
    if (isSidebarOpen && activeElement?.localName === 'body') {
      const sheet = document.getElementById(sheetId);
      // first element is the ModalHeader and second is the ModalBody
      const sheetBody = sheet?.children[1] as HTMLElement;
      sheetBody?.focus();
    }
  }, [document.activeElement, isSidebarOpen]);
  return sheetId;
};

export function Sidebar() {
  const isOpen = useGetSidebarOpen();
  const close = useCloseSidebar();
  const sideBarPage = useGetSidebarPage();

  const handleEscape = (event: { key: string }) => {
    if (event.key === 'Escape') {
      close();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }
    return () => document.removeEventListener('keydown', handleEscape);
  }, [isOpen, handleEscape]);
  // we need to recapture focus on the sidebar body after we have swap it
  const recaptureFocusId = useRecaptureFocus(isOpen);

  return (
    <SidebarRouter>
      <Modal
        data-testid="sidebar-modal"
        escapable={sideBarPage === 'more-menu'}
        visible={isOpen}
        handleCloseBtn={close}
      >
        <Sheets
          footer={SidebarRouter.Footer()}
          header={SidebarRouter.Header()}
          id={recaptureFocusId}
          labelledById={`${sideBarPage}-modalLabelId`}
        >
          <SidebarRouter.Body />
        </Sheets>
      </Modal>
    </SidebarRouter>
  );
}
