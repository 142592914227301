import { ArticleListItemType } from './types';
import {
  MISSING_ARTICLE_ITEM_NO,
  CdcFlow,
  ItemType,
  ReturnReason,
  ConditionOfGoods,
  StoFlow,
  Unit,
} from '../../models/CustomerReturn';
import { ReturnPrice } from '../../models/Price';

export const getArticleItemType = (
  article: {
    itemNo: string;
    itemType: ItemType;
    sourceLineRef?: string;
    returnReason?: ReturnReason;
    receiving?: StoFlow | CdcFlow;
    conditionOfGoods?: ConditionOfGoods;
    unit?: Unit;
  },
): ArticleListItemType => {
  const hasSourceLineRef = 'sourceLineRef' in article ? typeof article?.sourceLineRef === 'string' : false;
  if (!hasSourceLineRef) {
    return 'UNEXPECTED_ITEM';
  }

  const hasReceiving = typeof article.receiving !== 'undefined';
  if (hasReceiving) {
    return 'ITEM_WITH_RECEIVING_INFO';
  }

  if (article.itemType === 'ART') {
    return 'ARTICLE_ITEM';
  }

  const hasConditionOfGoods = typeof article.conditionOfGoods !== 'undefined';
  const hasReturnReason = typeof article.returnReason !== 'undefined';
  const hasUnit = typeof article.unit !== 'undefined';
  const itemNoIsMissingArticleNo = article.itemNo === MISSING_ARTICLE_ITEM_NO;
  if (hasConditionOfGoods && hasReturnReason && hasUnit && itemNoIsMissingArticleNo) {
    return 'MISSING_ARTICLE_ITEM';
  }

  return 'SERVICE_ITEM';
};

export const getPrices = (
  article: { price?: ReturnPrice },
  priceType: 'totalPrice' | 'unitPrice',
) => {
  const price = 'price' in article ? article.price?.[priceType] : null;
  return {
    priceExclTax: price?.priceExclTax ?? null,
    priceInclTax: price?.priceInclTax ?? null,
    currencyCode: price?.currencyCode ?? null,
  };
};
