import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import {
  useClosePrompt,
  useGetPromptLoading,
} from '../../store/features/prompt/promptSlice';
import { useCancelReturn } from '../../hooks/useCancelReturn/useCancelReturn';
import { useRescheduleReturn } from '../../hooks/useRescheduleReturn';
import { useGetFailedPickupReason, useSetFailedPickupReason } from '../../store/features/appStateSlice/appStateSlice';
import useHasPickupFailed from '../../hooks/useHasPickupFailed/useHasPickupFailed';
import { OTCApplicableActions, ViewModeApplicableActions } from '../../models';
import {
  useRemoveArticle,
  useRemoveBlindReturnArticle,
  useRemovePoP,
  useRemoveSelectedArticlesPop,
} from '../../store/features/standAloneActions/actions';

type ConfirmAction = Exclude<ViewModeApplicableActions, 'RE_SCHEDULE'> | OTCApplicableActions;

export function ConfirmActionFooter({ action }: { action: ConfirmAction }) {
  const setFailedPickup = useSetFailedPickupReason();
  const closePrompt = useClosePrompt();
  const isLoading = useGetPromptLoading();
  const cancelReturn = useCancelReturn();
  const { t } = useTranslation();
  const { rescheduleReturn } = useRescheduleReturn();
  const hasPickupFailed = useHasPickupFailed();
  const failedPickupReason = useGetFailedPickupReason();
  const removePop = useRemovePoP();
  const removeSelectedArticles = useRemoveSelectedArticlesPop();
  const removeArticle = useRemoveArticle();
  const removeBlindReturnArticle = useRemoveBlindReturnArticle();

  const actionsConfig: Record<typeof action, {
    actionFn: () => void,
    confirmLabel: string,
    cancelLabel: string
  }> = {
    RE_SCHEDULE_FAILED: {
      actionFn: rescheduleReturn,
      confirmLabel: t('prompt-actions.retry-reschedule.footer.primary-action'),
      cancelLabel: '',
    },
    CANCEL: {
      actionFn: cancelReturn,
      confirmLabel: t('prompt-actions.confirm-cancel.footer.primary-action'),
      cancelLabel: t('prompt-actions.confirm-cancel.footer.secondary-action'),
    },
    CANCEL_FAILED: {
      actionFn: cancelReturn,
      confirmLabel: t('prompt-actions.confirm-cancel.footer.primary-action'),
      cancelLabel: t('prompt-actions.confirm-cancel.footer.secondary-action'),
    },
    REMOVE_POP: {
      actionFn: removePop,
      confirmLabel: t('commonly-reused.remove'),
      cancelLabel: t('commonly-reused.cancel'),
    },
    REMOVE_SELECTED_ARTICLES_POP: {
      actionFn: removeSelectedArticles,
      confirmLabel: t('commonly-reused.remove'),
      cancelLabel: t('commonly-reused.cancel'),

    },
    REMOVE_ARTICLE: {
      actionFn: removeArticle,
      confirmLabel: t('commonly-reused.remove'),
      cancelLabel: t('commonly-reused.cancel'),
    },
    REMOVE_BLIND_RETURN_ARTICLE: {
      actionFn: removeBlindReturnArticle,
      confirmLabel: t('commonly-reused.remove'),
      cancelLabel: t('commonly-reused.cancel'),
    },
  };

  const onGoBack = () => {
    closePrompt();
    setFailedPickup(null);
  };

  return (
    <>
      <Button
        className="whitespace-nowrap !mr-2"
        disabled={isLoading}
        text={actionsConfig[action].cancelLabel || t('prompt-actions.confirm-cancel.footer.secondary-action')}
        type="secondary"
        onClick={onGoBack}
      />
      <Button
        data-testid="confirm-action"
        className="whitespace-nowrap"
        loading={isLoading}
        text={actionsConfig[action].confirmLabel}
        type="primary"
        onClick={actionsConfig[action].actionFn}
        disabled={hasPickupFailed && failedPickupReason === null}
      />
    </>
  );
}
// This is needed because Skapa validates the displayname to be like below
ConfirmActionFooter.displayName = 'Button';
