import { Questionnaire, IsomReturnOption } from 'shared-frontend';
import { useQuestionnaireRequest } from '../../../../components/app/initialFetchUtils';

export const useQuestionnaireForReturnMethod = (
  returnOption: IsomReturnOption | undefined,
): Questionnaire[] | null => {
  const { data: Qresp } = useQuestionnaireRequest();

  const hasQuestionnaire = returnOption
    && Object.keys(Qresp?.questionnaire || {}).includes(returnOption);

  return hasQuestionnaire ? (Qresp?.questionnaire?.[returnOption] ?? null) : null;
};
