import { nanoid } from '@reduxjs/toolkit';
import { getEnabledFeatures } from 'shared-frontend';
import {
  Sac, CustomerReturn,
} from '../models';
import {
  CustomerReturnItem,
  PaidBy,
} from '../models/CustomerReturn';
import { MarketConfig } from '../store/features/api/marketConfig/marketConfig';
import { mapSacPriceToReturnPrice } from '../store/features/appStateSlice/utils';
import { isValueOfType } from './validators';
import { programCodeValues, ProgramCode } from '../models/Sac';
import {
  itemTypeValues,
  ItemType,
  unitValues,
  Unit,
  stateOfGoodsValues,
  StateOfGoods,
} from '../models/SacItem';

const validateEnumInSacNode = <T>(
  value: any, allowedValues: Readonly<T[]>, errorSacNode: string,
) => {
  const isValueValid = isValueOfType<T>(value, allowedValues);
  if (isValueValid) return '';

  return `${errorSacNode} has incorrect ENUM value "${value}": allowed values are "${allowedValues}"`;
};

const validateSacItemsLength = (sac: Sac) => (sac.items && sac.items.length > 0 ? '' : '\'items\' is required.');

const validateItemEnums = (sac: Sac) => {
  let validationMessage = '';

  sac.items.every((item, index) => {
    validationMessage = validateEnumInSacNode<ItemType>(item.itemType, itemTypeValues, `item[${index}].itemType`);
    if (validationMessage.length > 0) return false;

    if (item.itemType === 'ART') {
      validationMessage = validateEnumInSacNode<StateOfGoods>(item.stateOfGoods, stateOfGoodsValues, `item[${index}].stateOfGoods`);
      if (validationMessage.length > 0) return false;

      validationMessage = validateEnumInSacNode<Unit>(item.unit, unitValues, `item[${index}].unit`);
      if (validationMessage.length > 0) return false;
    }

    return true;
  });

  return validationMessage;
};

const validateSacItemsNumber = (sac: Sac) => {
  let validationMessage = '';

  sac.items.forEach((item, index) => {
    if (!item.itemNo || item.itemNo.length === 0) {
      validationMessage = `item[${index}] has a missing value for 'itemNo', which is required.`;
    }
  });

  return validationMessage;
};

const validateProgramCodeEnums = (sac: Sac) => {
  let validationMessage = '';

  if (sac.customer.loyaltyMemberships?.length) {
    sac.customer.loyaltyMemberships.every((membership, index) => {
      validationMessage = validateEnumInSacNode<ProgramCode>(
        membership.programCode,
        programCodeValues,
        `customer.loyaltyMemberships[${index}].programCode`,
      );
      return validationMessage.length === 0;
    });
  }

  return validationMessage;
};

const validateTaxPrice = (sac: Sac, marketConfig: MarketConfig | null) => {
  const { purchaseInformation } = marketConfig || {};
  const firstItem = sac.items[0];
  const { includingTax: sacShowInclTax } = firstItem.price;
  const cfgShowExclTax = !!purchaseInformation?.itemPriceExcludesTax;

  if ((sacShowInclTax === cfgShowExclTax) || (!sacShowInclTax === !cfgShowExclTax)) {
    return 'showing of prices with tax is not matching market config';
  }

  return '';
};

const validateAuthUserId = (sac: Sac) => (sac.auth.userId ? '' : 'auth.userId is required');

const validateSacId = (sac: Sac) => (sac.sacId ? '' : 'sacId is required');

const validateCausingBusinessUnit = (sac: Sac) => {
  const { causingBusinessUnit } = sac;
  if (!causingBusinessUnit) {
    return 'causingBusinessUnit node is required';
  }

  if (!causingBusinessUnit.code || !causingBusinessUnit.type) {
    return 'causingBusinessUnit.code and causingBusinessUnit.type is required';
  }

  return '';
};

export const validateSacForCustomerReturn = (
  sac: Sac,
  marketConfig: MarketConfig | null,
): { isValid: boolean, message: string } => {
  const setValid = (message: string = '') => ({ isValid: !message, message });

  const validateSacItemsLengthMessage = validateSacItemsLength(sac);
  if (validateSacItemsLengthMessage.length > 0) {
    return setValid(validateSacItemsLengthMessage);
  }

  const sacItemsEnumsValidationMessage = validateItemEnums(sac);
  if (sacItemsEnumsValidationMessage.length > 0) {
    return setValid(sacItemsEnumsValidationMessage);
  }

  const sacItemsNumberValidationMessage = validateSacItemsNumber(sac);
  if (sacItemsNumberValidationMessage.length > 0) {
    return setValid(sacItemsNumberValidationMessage);
  }

  const programCodeEnumsValidationMessage = validateProgramCodeEnums(sac);
  if (programCodeEnumsValidationMessage.length > 0) {
    return setValid(programCodeEnumsValidationMessage);
  }

  const taxPriceValidationMessage = validateTaxPrice(sac, marketConfig);
  if (taxPriceValidationMessage.length > 0) {
    return setValid(taxPriceValidationMessage);
  }

  const authUserIdValidationMessage = validateAuthUserId(sac);
  if (authUserIdValidationMessage.length > 0) {
    return setValid(authUserIdValidationMessage);
  }

  const validateSacIdMessage = validateSacId(sac);
  if (validateSacIdMessage.length > 0) {
    return setValid(validateSacIdMessage);
  }

  const validateCausingBusinessUnitMessage = validateCausingBusinessUnit(sac);
  if (validateCausingBusinessUnitMessage.length > 0) {
    return setValid(validateCausingBusinessUnitMessage);
  }

  return setValid();
};

export function toCustomerReturn(
  sac: Sac,
  enabledFeatures: ReturnType<typeof getEnabledFeatures> = [],
): CustomerReturn {
  const {
    auth, isCredit, items, paidByIkea, paidByTsp, ...sacWithoutModifiedProps
  } = sac;
  const isPaidByTspEnabled = enabledFeatures.includes('showPaidByTsp');
  let paidBy:PaidBy;
  const paidByIsInvalid = paidByIkea && paidByTsp;
  switch (true) {
    case isPaidByTspEnabled && paidByIsInvalid:
      paidBy = 'INVALID';
      break;
    case isPaidByTspEnabled && paidByTsp:
      paidBy = 'TSP';
      break;
    case paidByIkea:
      paidBy = 'IKEA';
      break;
    default:
      paidBy = 'CUSTOMER';
      break;
  }

  return {
    ...sacWithoutModifiedProps,
    paidBy,
    isCreditPayment: isCredit,
    items: [
      ...items.map((item) => {
        const mappedPrices = mapSacPriceToReturnPrice({
          originalPrice: item.originalPrice,
          price: item.price,
        });

        const baseReturnedItem = {
          ...item,
          ...mappedPrices,
          id: nanoid(),
          sourceLineRef: String(item.sacLineId),
          ...('stateOfGoods' in item && {
            conditionOfGoods: item.stateOfGoods,
          }),
          ...('reasonCode' in item && 'subReasonCode' in item && {
            returnReason: {
              mainReasonCode: item.reasonCode,
              subReasonCode: item.subReasonCode,
            },
          }),
        };

        return baseReturnedItem as CustomerReturnItem;
      }),
    ],
  };
}
