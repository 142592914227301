import type { AppEndPointBuilder } from '../apiSlice';
import type {
  CustomerReturnResponse,
} from '../../../../models';
import { HistoryLogsResponse } from '../../../../models/HistoryLogResponse';

type GetHistoryLogsConfig = {
  countryCode: string;
  returnId: string;
};

export const fetchCustomerReturnApi = (
  countryCode: string,
  returnId: CustomerReturnResponse['returnId'],
) => ({ url: `/v2/${countryCode}/return-views/cw/${returnId}/history-logs`, method: 'GET' });

export const getHistoryLogsBuilder = (
  builder: AppEndPointBuilder,
) => builder.query<HistoryLogsResponse, GetHistoryLogsConfig>({
  query: (config: GetHistoryLogsConfig) => {
    const { countryCode, returnId } = config;
    const { url, method } = fetchCustomerReturnApi(countryCode, returnId);
    return { url, method };
  },
});
