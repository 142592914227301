import { SidebarHeader } from '../SidebarHeader';
import {
  useGetBlindReturnArticleForActionsSidebar,
} from '../../../store/features/OTCSlice/OTCSlice';

export function OTCBlindReturnArticleActionsHeader() {
  const item = useGetBlindReturnArticleForActionsSidebar();

  return (
    <SidebarHeader title={item?.name || ''} />
  );
}
