import { useRoute } from 'wouter';
import { useMarketLocale } from './useMarketLocale';

export const urlMap = {
  BASE: '/returns',
  EMBEDDED: '/embedded',
  CREATE: '/create',
  VIEW: '/view/',
  BASE_SUBMIT: '/submit',
};

export function getReturnIdentifierFromUrl(url: string) {
  const match = url.match(/\/view\/([^/]+)/);
  const returnId = match ? match[1] : null;
  return returnId;
}

export default function useAppRoutes() {
  const { countryCode, languageCode } = useMarketLocale();
  const {
    CREATE, VIEW, BASE, EMBEDDED, BASE_SUBMIT,
  } = urlMap;
  const root = `/${countryCode.toLocaleLowerCase()}/${languageCode}`;
  return {
    base: `${root}${BASE}`,
    baseSubmit: BASE_SUBMIT,
    embedded: EMBEDDED,
    create: CREATE,
    view: `${VIEW}:rest*`,
    allBaseRoutes: `/:${BASE_SUBMIT.replace('/', '')}?`,
    // we need this for the hooks because in tests the paths are absolute
    // but in prod the router scopes them
    urlWildCard: '/:country?/:lang?/:base?/:embedded?',
  };
}

export const useCreateRoute = () => {
  const { create, urlWildCard } = useAppRoutes();
  const [match] = useRoute(`${urlWildCard}${create}/`);
  return match;
};

export const useViewRoute = () => {
  const { view, urlWildCard } = useAppRoutes();
  const [match] = useRoute(`${urlWildCard}${view}`);
  return match;
};

export const useBaseRoute = () => {
  const [match] = useRoute('/');
  return match;
};

export const useBaseSubmitRoute = () => {
  const { baseSubmit } = useAppRoutes();
  const [match] = useRoute(baseSubmit);
  return match;
};
