import { MarketConfig } from '../../store/features/api/marketConfig/marketConfig';
import { STATUS } from '../tokenUtils';

export function setupErrorMessage(e: unknown) {
  const message = (e instanceof Error && e?.message) || 'Unknown error';

  return {
    ...STATUS.UNKNOWN,
    message: `${STATUS.UNKNOWN.message}, ${message}`,
  };
}

export const isMarketConfigError = (
  marketConfig: MarketConfig | { error: unknown },
): marketConfig is { error: unknown } => Object.prototype.hasOwnProperty.call(marketConfig ?? {}, 'error');

export class MarketConfigError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message: string, options: { cause: unknown }) {
    super(message, options);
  }
}

export const handleMarketConfigError = (
  { marketConfig, sacId, returnIdentifier }:
  { marketConfig: MarketConfig | { error:unknown }, sacId?: string, returnIdentifier?: string },
) => {
  if (isMarketConfigError(marketConfig)) {
    const baseMessage = 'MarketConfig failed to fetch for';
    const viewModeMessage = `${baseMessage} return with Id: ${returnIdentifier}`;
    const createModeMessage = `${baseMessage} SAC-Id: ${sacId}`;

    const enrichedError = new MarketConfigError(
      sacId ? createModeMessage : viewModeMessage,
      { cause: marketConfig.error },
    );
    throw enrichedError;
  }
};
