/* eslint-disable arrow-body-style */
import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import {
  CompletedQuestionnaire,
  InternalQuestionnaireState,
  questionnaireAnswersInitialState as qcInitialState,
  useSelectedReturnMethodState,
  ParsedTimeWindow,
  createCompletedQuestionnaire,
  ApiRespType,
  SelectedReturnMethod,
  IsomReturnOption,
  removeTimeZoneDifference,
  SelectedTimeWindow,
} from 'shared-frontend';
import i18next from 'i18next';
import type { RootState } from '../../app/store';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import { returnMethodSaved, questionnaireSaved, timeWindowSet } from '../standAloneActions/actions';
import { closeSidebar, openSidebarToPage } from '../sidebar/sidebarSlice';
import { formatBodyForApi } from '../api/submitReturn/submitReturnUtils';
import { RefundCalcResponse } from '../../../models/refundCalcResponse';
import { apiSlice } from '../api/apiSlice';
import { mergeQuestionnaireAnswers, matchQuestionnaireTypeToApiResponse, questionnaireAnswersToCompleted } from './reducerUtils';
import { CustomerReturn } from '../../../models';
import { closePrompt } from '../prompt/promptSlice';

type ReturnMethodsMarketConfig = {
  [K in IsomReturnOption]: {
    isPickUp: boolean;
  }
};
type SerializedTimeWindow = Omit<ParsedTimeWindow, 'fromDateTime' | 'toDateTime'> & { fromDateTime: string, toDateTime: string };
export interface SelectedReturnMethodState {
  selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null;
  questionnaireCompleted: CompletedQuestionnaire | null;
  questionnaireControl: InternalQuestionnaireState | null;
  draft: {
    selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null;
    questionnaireControl: InternalQuestionnaireState;
    questionnaireValid: boolean;
    selectedDateSubOptionData: {
      timeWindow: SerializedTimeWindow;
    } | null;
    reschedule: {
      originalTimeWindow: SelectedTimeWindow,
      successfulRescheduleTimeWindowId?: string,
    } | null
  };
  returnMethodsMarketConfig: ReturnMethodsMarketConfig | null;
}
const initialQuestionnaireValid = true;
export const initialState: SelectedReturnMethodState = {
  selectedReturnMethod: null,
  questionnaireCompleted: null,
  questionnaireControl: null,
  draft: {
    selectedReturnMethod: null,
    questionnaireControl: qcInitialState,
    questionnaireValid: initialQuestionnaireValid,
    selectedDateSubOptionData: null,
    reschedule: null,
  },
  returnMethodsMarketConfig: null,
};

export const selectedReturnMethodSlice = createSlice({
  name: 'selectedReturnMethod',
  initialState,
  reducers: {
    setSelectedReturnMethodDraft: (
      state: SelectedReturnMethodState,
      action: PayloadAction<SelectedReturnMethod<IsomReturnOption> | null>,
    ) => {
      if (action.payload === null) {
        return;
      }
      state.draft.selectedReturnMethod = action.payload;
    },
    setSelectedDateSubOptionDraft: (
      state: SelectedReturnMethodState,
      action: PayloadAction<{
        timeWindow: SerializedTimeWindow;
      } | null>,
    ) => {
      state.draft.selectedDateSubOptionData = action.payload;
    },
    setQuestionnaireControl: (
      state: SelectedReturnMethodState,
      action: PayloadAction<InternalQuestionnaireState>,
    ) => {
      state.draft.questionnaireControl = action.payload;
    },
    setQuestionnaireValid: (
      state: SelectedReturnMethodState,
      action: PayloadAction<boolean>,
    ) => {
      state.draft.questionnaireValid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(returnMethodSaved, (state, action) => {
        if (action.payload?.hasQuestionnaire) {
          state.draft.questionnaireControl = qcInitialState;
          state.draft.questionnaireValid = initialQuestionnaireValid;
          state.questionnaireCompleted = null;
        }
        state.selectedReturnMethod = state.draft.selectedReturnMethod;
        state.draft.selectedReturnMethod = null;
      })
      .addCase(timeWindowSet, (state, action) => {
        const draft = state.draft.selectedReturnMethod;
        const newTimeWindow = state.draft.selectedDateSubOptionData?.timeWindow.timeWindowRaw;

        if (!draft || !newTimeWindow) return;

        const updateCallback = (
          updatedSelectedReturnMethod: SelectedReturnMethod<IsomReturnOption>,
        ) => {
          state.draft.selectedReturnMethod = updatedSelectedReturnMethod;
        };

        const [,
          updateSelectedReturnMethodTimeWindow,
        ] = useSelectedReturnMethodState<IsomReturnOption>((
          updatedSelectedReturnMethod,
        ) => {
          if (!updatedSelectedReturnMethod) return;

          updateCallback(updatedSelectedReturnMethod);
        });

        updateSelectedReturnMethodTimeWindow(
          draft,
          newTimeWindow,
        );

        if (action.payload.hasCommittedMethod) {
          state.selectedReturnMethod = state.draft.selectedReturnMethod;
          state.draft.selectedReturnMethod = null;
        }
      })
      .addCase(questionnaireSaved, (state) => {
        const control = state.questionnaireControl
          || state.draft.questionnaireControl;
        const completedQuestionnaire = createCompletedQuestionnaire(control);
        state.questionnaireCompleted = completedQuestionnaire;
        state.questionnaireControl = state.draft.questionnaireControl;
      })
      .addCase(closeSidebar, (state, action) => {
        if (action.payload !== 'questionnaire') return;
        state.draft.questionnaireControl = qcInitialState;
        state.draft.questionnaireValid = initialQuestionnaireValid;
        state.draft.selectedDateSubOptionData = null;
      })
      .addCase(openSidebarToPage, (state, action) => {
        if (state.selectedReturnMethod) {
          state.draft.selectedReturnMethod = state.selectedReturnMethod;
          if (action.payload.page === 'time-window-calendar' && action.payload.originalTimeWindow) {
            state.draft.reschedule = {
              ...state.draft.reschedule,
              originalTimeWindow: {
                ...action.payload.originalTimeWindow,
                timeWindowId: action.payload.originalTimeWindow.id,
              },
            };
          }
        }
      })
      .addCase(closePrompt, (state, action) => {
        const isRescheduleRetry = action.payload === 'retry-reschedule';
        if (
          isRescheduleRetry
          && state.selectedReturnMethod?.timeWindow
          && state.draft.reschedule?.originalTimeWindow) {
          state.selectedReturnMethod.timeWindow = state.draft.reschedule.originalTimeWindow;
        }
      })
      .addMatcher(apiSlice.endpoints.getCustomerReturn.matchFulfilled, (state, action) => {
        const {
          id,
          timeWindow,
          price,
          originalPrice,

        } = action.payload.returnAgreement.returnSettlement.returnOption;
        const tspData = action.payload.returnAgreement.returnSettlement
          .returnOption?.metadata?.timeWindow?.tspData;
        const questionnaire = action.payload.customer?.questionnaire;
        const { receivingBusinessUnit, returnAgreement } = action.payload;
        const taxationInfo = returnAgreement.returnSettlement.returnOption?.taxationInfo;

        const selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> = {
          id,
          isPickUp: (
            state.returnMethodsMarketConfig?.[id]?.isPickUp
          ) || false,
          name: i18next.t(`returnOptions.${id}.title`) || '',
          price,
          originalPrice,
          ...(timeWindow && {
            timeWindow: {
              timeWindowId: timeWindow.id,
              fromDateTime: timeWindow.fromDateTime,
              toDateTime: timeWindow.toDateTime,
              tspData,
            },
          }),
          receivingBusinessUnit,
          taxationInfo: taxationInfo || '',
        };
        state.selectedReturnMethod = selectedReturnMethod;
        if (selectedReturnMethod.timeWindow) {
          state.draft.reschedule = {
            originalTimeWindow: selectedReturnMethod.timeWindow,
          };
        }
        state.questionnaireCompleted = questionnaireAnswersToCompleted(questionnaire);
      })
      .addMatcher(apiSlice.endpoints.questionnaire.matchFulfilled, (state, action) => {
        if (!state.questionnaireCompleted) return;

        const returnOption = state.selectedReturnMethod?.id;

        if (!returnOption) return;

        const questionnaireFromApi = matchQuestionnaireTypeToApiResponse(
          action.payload.questionnaire[returnOption],
          state.questionnaireCompleted?.questionnaire.type,
        );

        if (!questionnaireFromApi) return;

        const mergedQuestions = mergeQuestionnaireAnswers(
          questionnaireFromApi,
          state.questionnaireCompleted?.questionnaire,
        );

        const control: InternalQuestionnaireState = {
          type: questionnaireFromApi.type,
          translatedType: questionnaireFromApi.translatedType,
          questions: mergedQuestions,
        };

        state.questionnaireControl = control;
      })
      .addMatcher(
        apiSlice.endpoints.marketConfig.matchFulfilled,
        (state, action) => {
          const options = action.payload.returnOptions?.options;
          if (!options) return;

          const config: ReturnMethodsMarketConfig = {} as ReturnMethodsMarketConfig;
          const isomKeys = Object.keys(options).filter((key) => key.includes('isom')) as IsomReturnOption[];

          isomKeys.forEach((key) => {
            const returnMethod = options[key];
            if (returnMethod) {
              config[key] = {
                isPickUp: Boolean(returnMethod.isPickUp),
              };
            }
          });

          state.returnMethodsMarketConfig = config;
        },
      )
      .addMatcher(
        apiSlice.endpoints.rescheduleReturn.matchFulfilled,
        (state, action) => {
          if (state.draft.reschedule) {
            state.draft.reschedule.successfulRescheduleTimeWindowId = action
              .payload.rescheduledWithTimeWindowId;
          }
        },
      );
  },
});

export const useGetSelectedReturnMethod = () => useLamnaSelector(
  (state) => state.selectedReturnMethod.selectedReturnMethod,
);

export const useGetQuestionnaire = () => useLamnaSelector(
  (state) => state.selectedReturnMethod.questionnaireCompleted,
);
export const useGetIsValidQuestionnaireDraft = () => useLamnaSelector(
  (state) => state.selectedReturnMethod.draft.questionnaireValid,
);
export const useGetSubmitReturnBody = (
  refund?: RefundCalcResponse,
  questionnaireResponse?: ApiRespType<IsomReturnOption>['questionnaire'],
) => useLamnaSelector(
  (state) => {
    const { customerReturn, preferredCommunicationMethod, userId } = state.appState;
    const { selectedReturnMethod } = state.selectedReturnMethod;

    if (!customerReturn || !selectedReturnMethod || !userId) {
      return null;
    }

    const questionnaire = state.selectedReturnMethod.questionnaireCompleted;

    const hasQuestionnaire = Boolean(
      selectedReturnMethod && Object.keys(
        questionnaireResponse?.questionnaire || {},
      ).includes(selectedReturnMethod.id),
    );

    const isQuestionnaireValid = !!questionnaire;
    if ((hasQuestionnaire && !isQuestionnaireValid) || !refund) {
      return null;
    }

    return formatBodyForApi({
      customerReturnContent: customerReturn as CustomerReturn,
      refund,
      hasQuestionnaire,
      preferredCommunicationMethod,
      questionnaire,
      selectedReturnMethod,
      userId,
    });
  },
);

// only expose those actions that are needed outside of this slice
const {
  setSelectedReturnMethodDraft,
  setSelectedDateSubOptionDraft,
  setQuestionnaireControl,
  setQuestionnaireValid,
} = selectedReturnMethodSlice.actions;

// wrap the store action with the hooks from the shared front end that handle data mapping
const useReturnMethodSetters = (
  onSelectedReturnMethod: (
    selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null
  ) => void,
) => {
  const [
    selectedReturnMethodSetter,
    updateSelectedReturnMethodTimeWindow,
  ] = useSelectedReturnMethodState(
    onSelectedReturnMethod,
  );
  return {
    selectedReturnMethodSetter,
    updateSelectedReturnMethodTimeWindow,
  };
};

export const useSelectedReturnMethodDraft = () => {
  const dispatch = useLamnaDispatch();
  const setters = useReturnMethodSetters(
    (selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null) => {
      dispatch(setSelectedReturnMethodDraft(selectedReturnMethod));
    },
  );

  return {
    setReturnMethodDraft: setters.selectedReturnMethodSetter,
    setReturnMethodTimeWindowDraft: setters.updateSelectedReturnMethodTimeWindow,
  };
};

export const useSetSelectedDateSubOptionDraft = () => {
  const dispatch = useLamnaDispatch();
  return (tw: ParsedTimeWindow) => {
    const timeWindow = {
      ...tw,
      fromDateTime: (tw.timeWindowRaw.fromDateTime),
      toDateTime: (tw.timeWindowRaw.toDateTime),
    };

    return (
      dispatch(setSelectedDateSubOptionDraft({ timeWindow }))
    );
  };
};

export const useQuestionnaireControl = () => {
  const dispatch = useLamnaDispatch();
  return ({
    setQuestionnaireControl: (questionnaire: InternalQuestionnaireState) => {
      dispatch(setQuestionnaireControl(questionnaire));
    },
    questionnaireControlState: useLamnaSelector(
      (state: RootState) => state.selectedReturnMethod.questionnaireControl
        || state.selectedReturnMethod.draft.questionnaireControl,
    ),
  });
};

export const useSetQuestionnaireValid = () => {
  const dispatch = useLamnaDispatch();
  return ((isValid: boolean) => {
    dispatch(setQuestionnaireValid(isValid));
  });
};

export const useGetSelectedReturnMethodDraft = () => {
  const selectDraft = (state: RootState) => state.selectedReturnMethod.draft;

  const selectDraftSelectedSubOptionData = createSelector([
    selectDraft,
  ], (draft) => draft.selectedDateSubOptionData);

  const selectSubOptionDataTimeWindow = createSelector([
    selectDraftSelectedSubOptionData,
  ], (selectedDateSubOptionData) => selectedDateSubOptionData?.timeWindow);

  const selectFromTime = createSelector([
    selectSubOptionDataTimeWindow,
  ], (timeWindow) => timeWindow?.fromDateTime);

  const selectToTime = createSelector([
    selectSubOptionDataTimeWindow,
  ], (timeWindow) => timeWindow?.toDateTime);

  const memoizedSelector = createSelector(
    [
      selectDraft,
      selectDraftSelectedSubOptionData,
      selectSubOptionDataTimeWindow,
      selectFromTime,
      selectToTime,
    ],
    (draft, selectedDateSubOptionData, timeWindow, fromDateTime, toDateTime) => (
      {
        ...draft,
        selectedDateSubOptionData: selectedDateSubOptionData && timeWindow?.timeWindowId ? {
          ...selectedDateSubOptionData,
          timeWindow: {
            ...timeWindow,
            fromDateTime: removeTimeZoneDifference(
              fromDateTime as string,
            ),
            toDateTime: removeTimeZoneDifference(
              toDateTime as string,
            ),
          },
        } : null,
      }
    ),
  );
  return useLamnaSelector(memoizedSelector);
};

export default selectedReturnMethodSlice.reducer;
