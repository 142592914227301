import { MarketConfigFetch } from '../../../AuthAndFoundationRenderApp';
import App from '../App';
import { useCreateInitialFetch } from '../initialFetchUtils';

export default function CreateReturn() {
  useCreateInitialFetch();
  return (
    <MarketConfigFetch>
      <App />
    </MarketConfigFetch>
  );
}
