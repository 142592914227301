import { ViewModeApplicableActions } from '../../models';
import { useGoToPromptPage } from '../../store/features/prompt/promptSlice';
import { useCloseSidebar, useGoToSidebarPage } from '../../store/features/sidebar/sidebarSlice';

const useAvailableActionsConfig = () => {
  const openCancelationPrompt = useGoToPromptPage('confirm-cancel');
  const openFailedPickupCancelationPrompt = useGoToPromptPage('failed-pickup-cancel');
  const closeSideBar = useCloseSidebar();
  const goToTimeWindowCalendar = useGoToSidebarPage('time-window-calendar');

  const availableActions: Record<ViewModeApplicableActions, (() => void)> = {
    CANCEL: () => {
      openCancelationPrompt();
      closeSideBar();
    },
    RE_SCHEDULE: () => {
      goToTimeWindowCalendar();
    },
    RE_SCHEDULE_FAILED: () => {
      goToTimeWindowCalendar();
    },
    CANCEL_FAILED: () => {
      openFailedPickupCancelationPrompt();
      closeSideBar();
    },

  };
  return availableActions;
};

export default useAvailableActionsConfig;
