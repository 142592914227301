import { MarketConfigFetch } from '../../../AuthAndFoundationRenderApp';
import App from '../App';
import { useViewInitialFetch } from './useViewInitialFetch';

export default function ViewReturn() {
  useViewInitialFetch();
  return (
    <MarketConfigFetch>
      <App />
    </MarketConfigFetch>
  );
}
