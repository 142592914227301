import {
  useQuestionnaireQuery,
  apiSlice,
  useAvailableReturnMethodsQuery,
} from '../../store/features/api/apiSlice';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import {
  useGetArticlesWithIncorrectUnit,
  useGetCustomerReturn,
} from '../../store/features/appStateSlice/appStateSlice';
import { CustomerReturnItem } from '../../models';
import { ReceivedItem } from '../../models/CustomerReturn';

type InitialRequestData = {
  postCode: string | null;
  countryCode: string | null;
  items: (CustomerReturnItem | ReceivedItem)[];
  languageCode: Lowercase<string>;
};

export const useInitialRequestData = (): InitialRequestData => {
  const customerReturn = useGetCustomerReturn();
  const { languageCode } = useMarketLocale();

  if (customerReturn === null) {
    return {
      postCode: null,
      countryCode: null,
      items: [],
      languageCode,
    };
  }

  return {
    postCode: customerReturn.customer?.deliveryInfo?.postCode ?? null,
    countryCode: customerReturn.customer?.deliveryInfo?.country ?? null,
    items: customerReturn.items,
    languageCode,
  };
};

const createQuestionnaireQuery = () => {
  const { postCode, countryCode, languageCode } = useInitialRequestData();

  return {
    countryCode: countryCode!,
    languageCode,
    postCode: postCode!,
  };
};

// will refetch on arg change or if query hasn't been initialized
export const useQuestionnaireRequest = (
  { skipCondition } = { skipCondition: false },
) => {
  const query = createQuestionnaireQuery();
  const skip = query.postCode === null || query.countryCode === null || skipCondition;
  return useQuestionnaireQuery(
    query,
    {
      skip,
    },
  );
};
// wont refetch regardless of arg change or if the query hasn't been initialized
export const useQuestionnaireResponse = () => {
  const query = createQuestionnaireQuery();
  return apiSlice.endpoints.questionnaire.useQueryState(query);
};

const createReturnMethodsQuery = () => {
  const { postCode, items, countryCode } = useInitialRequestData();
  const customerReturn = useGetCustomerReturn();
  const paidBy = customerReturn === null ? 'INVALID' : customerReturn.paidBy;
  return {
    apiConfig: {
      countryCode: countryCode!,
      postCode: postCode!,
      items: items as CustomerReturnItem[],
      paidBy,
      businessUnit: customerReturn?.causingBusinessUnit!,
    },
    skip: postCode === null
      || countryCode === null
      || items.length === 0
      || customerReturn === null
      || paidBy === 'INVALID',
  };
};

export const useAvailableReturnMethodsRequest = (
  { skipCondition } = { skipCondition: false },
) => {
  const {
    apiConfig,
    skip,
  } = createReturnMethodsQuery();
  return useAvailableReturnMethodsQuery(
    apiConfig,
    { skip: skip || skipCondition },
  );
};

export const useAvailableReturnMethodsResponse = () => {
  const {
    apiConfig,
  } = createReturnMethodsQuery();
  return apiSlice.endpoints.availableReturnMethods.useQueryState(apiConfig);
};

export const useCreateInitialFetch = () => {
  const articlesWithIncorrectUnit = useGetArticlesWithIncorrectUnit();
  const skipRequests = articlesWithIncorrectUnit === null || articlesWithIncorrectUnit.length > 0;

  const questionnaireResponse = useQuestionnaireRequest({ skipCondition: skipRequests });
  const returnOptionsResponse = useAvailableReturnMethodsRequest({ skipCondition: skipRequests });

  return { questionnaireResponse, returnOptionsResponse };
};
