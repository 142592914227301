import Text, { TextProps } from '@ingka/text';
import SSRIcon, { SSRIconProps } from '@ingka/ssr-icon';
import { useEffect, useState } from 'react';

interface CustomListItemProps {
  icon?: SSRIconProps['paths']
  textTransition?: string,
  label: string
  labelSize: TextProps['headingSize']
  onClick?: () => void
}

interface TransitionTextProps {
  text: string
  transitionTo?: string
  labelSize: TextProps['headingSize']
  condition?: boolean
}

function TransitionText({
  text, transitionTo, labelSize, condition,
}: TransitionTextProps) {
  return (
    <Text
      tagName="span"
      headingSize={labelSize}
      className="flex-auto text-left "
    >
      {condition && transitionTo ? transitionTo : text}
    </Text>
  );
}

// Skapa recommends 5 to 10 seconds based on length of the text, keeping it at 5 for simplicity
// Setting it to small timeout for tests to have the tests run faster
const CLICK_TIMEOUT = import.meta.env.MODE === 'test' ? 100 : 5000;

export function CustomListItem({
  icon,
  textTransition,
  label,
  labelSize,
  onClick,
}: CustomListItemProps) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (clicked) setClicked(false);
    }, CLICK_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [clicked]);

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
    if (textTransition) {
      setClicked(true);
    }
  };

  return (
    <li
      className=" hover:bg-neutral-grey-100 hover:underline flex flex-row "
    >
      <button onClick={onClickHandler} type="button" className="w-full flex flex-row h-full pl-6 pr-6 pt-7 pb-7 ">
        <TransitionText
          text={label}
          transitionTo={textTransition}
          labelSize={labelSize}
          condition={clicked}
        />
        {icon && (
          <span className="flex flex-row justify-items-end">
            <SSRIcon paths={icon} ariaHidden />
          </span>
        )}
      </button>
    </li>
  );
}
