import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';

export function CreateFailedMessage() {
  const { t } = useTranslation();

  return (
    <InlineMessage
      body={(
        <Text tagName="span">{t('createFailedInlineMessage.body')}</Text>
      )}
      className="my-4 m:w-fit"
      data-testid="return-create-failed"
      title={t('createFailedInlineMessage.title')}
      variant="negative"
    />
  );
}
