import { ModalBody } from '@ingka/modal';
import { QuestionnaireComponent } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { useQuestionnaireForReturnMethod } from '../../../store/features/api/questionnaire/hooks';
import { useQuestionnaireControl, useGetSelectedReturnMethod, useSetQuestionnaireValid } from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useGetSidebarOpen } from '../../../store/features/sidebar/sidebarSlice';
import { useGetReturnReference } from '../../../store/features/appStateSlice/appStateSlice';

export function QuestionnairePage() {
  const selectedReturnMethod = useGetSelectedReturnMethod();
  const setQuestionnaireValid = useSetQuestionnaireValid();
  const {
    questionnaireControlState,
    setQuestionnaireControl,
  } = useQuestionnaireControl();
  const returnReference = useGetReturnReference();
  const open = useGetSidebarOpen();
  const { t } = useTranslation();

  const questionnaireLabels = {
    livingArrangement: t('questionnaire-sidebar.livingArrangement'),
    continueButton: t('commonly-reused.continue'),
  };

  const questionLabels = {
    additionalInfo: t('questionnaire-sidebar.additional-info-input.label'),
    chooseOption: t('commonly-reused.chooseAnOption'),
    optionalField: t('questionnaire-sidebar.additional-info-input.optional'),
    maxLength: t('questionnaire-sidebar.additional-info-input.maxlengthError'),
    required: t('commonly-reused.fieldRequired'),
    answerLabels: {
      na: t('questionnaire-sidebar.na'),
      yes: t('questionnaire-sidebar.yes'),
      no: t('questionnaire-sidebar.no'),
    },
    regex: {
      FLOOR_NO: t('questionnaire-sidebar.validation-errors.floorNo'),
    },
  };

  const questionnaire = useQuestionnaireForReturnMethod(selectedReturnMethod?.id);

  if (!questionnaire || !open) {
    return null;
  }

  const viewMode = Boolean(returnReference);

  return (
    <ModalBody>
      <QuestionnaireComponent
        questionnaire={questionnaire}
        questionnaireControlState={questionnaireControlState}
        questionLabels={questionLabels}
        questionnaireLabels={questionnaireLabels}
        onQuestionnaireChange={setQuestionnaireControl}
        onValidationChange={setQuestionnaireValid}
        viewMode={viewMode}
        withoutSubmitButton
      />
    </ModalBody>
  );
}
