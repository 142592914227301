import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';

function setPublicBasePath() {
  const dynamicCdn = `${window.config.CDN_PATH}assets/`;

  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__dynamicImportHandler__ = (importer) => dynamicCdn + importer;

  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__dynamicImportPreload__ = (preloads) => preloads.map(
    (preload: string) => dynamicCdn + preload,
  );
}

export function getCountryAndLanguageFromUrl() {
  const [, ...countryLanguage] = window?.location?.pathname?.match(/\/([a-zA-Z-]{2})\/([a-zA-Z-]{2})\/return/) || [];
  return countryLanguage;
}

export default function initTranslations() {
  const languageDetector = new LanguageDetector();

  /**
   * Extracts locale based on url path
   *
   * IKEA puts the locale in the url path ikea.com/COUNTRY_CODE/LANGUAGE_CODE
   * @returns {string | undefined} string on format "sv-se", i.e. LANGUAGE_CODE-COUNTRY_CODE
  */
  const localeLookup = () => {
    const [country, language] = getCountryAndLanguageFromUrl();

    if (language && country) {
      setPublicBasePath();
      const locale = `${language}-${country}`;
      document.documentElement.lang = locale;
      return locale;
    }

    return undefined;
  };

  const detector: CustomDetector = {
    name: 'path-country-and-lang',
    lookup: localeLookup,
  };

  languageDetector.addDetector(detector);

  const detection: DetectorOptions = {
    order: ['path-country-and-lang'],
  };

  const config = window.config ?? import.meta.env;

  const backend: HttpBackendOptions = {
    loadPath: (locale, ns) => {
      const [lang, country] = locale[0].split('-');
      return import.meta.env.DEV
        ? `/${ns}.json`
        : `${config.VITE_RETURNS_URL}/v1/${country.toUpperCase()}/${lang}/cw-${ns}`;
    },
    crossDomain: true,
  };

  i18n
    .use(languageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      detection,
      backend,
      load: 'currentOnly',
      fallbackLng: {
        default: ['en-XZ'],
      },
      ns: ['translations'],
      defaultNS: 'translations',
      debug: false,
      parseMissingKeyHandler: () => '',
    });
}
