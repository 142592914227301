import ListView, { ListViewItem } from '@ingka/list-view';
import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import copy from '@ingka/ssr-icon/paths/copy';
import { useApplicableActions } from '../../../hooks/useApplicableActions';
import useAvailableActionsConfig from '../../../hooks/useAvailableActionsConfig/useAvailableActionsConfig';
import { ViewModeApplicableActions } from '../../../models';
import { useGetEnabledFeature, useGetSSUIUrl } from '../../../store/features/appStateSlice/appStateSlice';
import { CustomListItem } from '../CustomListItem/CustomListItem';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { ViewRouteOnly } from '../../RouteOnly/RouteOnly';
import { copyToClipboard } from '../../../utils/copyToClipboard';

const mapFailedPickupActions = (action: ViewModeApplicableActions) => {
  if (action === 'RE_SCHEDULE_FAILED') {
    return 'RE_SCHEDULE';
  }

  if (action === 'CANCEL_FAILED') {
    return 'CANCEL';
  }
  return action;
};

export function MoreMenuBody() {
  const { t } = useTranslation();
  const { actions } = useApplicableActions();
  const selfServiceViewURL = useGetSSUIUrl();
  const actionsConfig = useAvailableActionsConfig();
  const goToHistoryLog = useGoToSidebarPage('history-log');
  const isHistoryLogEnabled = useGetEnabledFeature('historyLog');

  if (!actions && !selfServiceViewURL && !isHistoryLogEnabled) return null;

  return (
    <ModalBody className="p-[0_!important]">
      <ListView id="show-more-list-view" size="large" showDivider={false}>
        {actions?.map((action) => (
          <ListViewItem
            className="hover:bg-neutral-grey-100 [&_svg]:hidden"
            control="navigational"
            emphasised
            inset
            key={action}
            onClick={actionsConfig[action]}
            title={t(`show-more.actions.${mapFailedPickupActions(action).toLowerCase()}`)}
          />
        ))}
        {isHistoryLogEnabled && (
          <ViewRouteOnly>
            <ListViewItem
              className="hover:bg-neutral-grey-100 [&_svg]:hidden"
              control="navigational"
              emphasised
              inset
              onClick={goToHistoryLog}
              title={t('show-more.history-log.title')}
            />
          </ViewRouteOnly>
        )}
        {selfServiceViewURL && (
          <>
            {actions && (
              <hr className=" border-t-neutral-grey-200 border-t-3 mb-0 mt-0 mr-3 ml-3" />
            )}
            <CustomListItem
              icon={copy}
              label={t('commonly-reused.copyCustomerTracking')}
              labelSize="xs"
              onClick={() => copyToClipboard(selfServiceViewURL || '')}
              textTransition={t('commonly-reused.copied')}
            />
          </>
        )}
      </ListView>
    </ModalBody>
  );
}
