import { Validator } from '@resolutions/scanner-contract';
import { PopValidator } from '@resolutions/scanner-contract/src/validators/popValidator';
import { useMarketConfigFetch } from '../../../useMarketConfigFetch';

export type PopPlugin = PopValidator['plugins'][number]['name'];
export type PopId = PopValidator['id'];

export const POP_VALIDATOR_ID: PopId = 'proofOfPurchase';
export const IN_STORE_POP_NAME: PopPlugin = 'inStorePurchase';
export const ONLINE_POP_NAME: PopPlugin = 'onlinePurchase';

export const usePopValidator = () => {
  const { data: marketConfig } = useMarketConfigFetch();
  const popDateFormat = marketConfig?.proofOfPurchase.dateFormat;

  if (!popDateFormat) {
    return [];
  }

  const inStorePurchasePlugin = {
    name: IN_STORE_POP_NAME,
    dateFormat: popDateFormat,
  } as const;

  const onlinePurchasePlugin = {
    name: ONLINE_POP_NAME,
  } as const;

  const popValidator: Validator[] = [
    {
      id: POP_VALIDATOR_ID,
      plugins: [
        inStorePurchasePlugin,
        onlinePurchasePlugin,
      ],
    },
  ];
  return popValidator;
};
