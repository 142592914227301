import Button from '@ingka/button';
import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import { useCloseSidebar } from '../../store/features/sidebar/sidebarSlice';

export function SidebarCloseFooter() {
  const { t } = useTranslation();
  const closeSideBar = useCloseSidebar();

  return (
    <ModalFooter>
      <Button
        text={t('commonly-reused.close')}
        onClick={closeSideBar}
      />
    </ModalFooter>
  );
}
