import { AvailableTimeWindows } from 'shared-frontend/src/models/return-method';

export const sortTimeWindows = (timeWindows: AvailableTimeWindows) => {
  const sortedIds = Object.keys(timeWindows).sort((a, b) => {
    const toDate = new Date(timeWindows[a].toDateTime).getTime();
    const fromDate = new Date(timeWindows[b].fromDateTime).getTime();
    return toDate - fromDate;
  });

  const sortedTimeWindows: AvailableTimeWindows = {};
  sortedIds.forEach((id) => {
    sortedTimeWindows[id] = timeWindows[id];
  });
  return sortedTimeWindows;
};
