import { AvailableTimeWindows, TimeWindow } from 'shared-frontend/src/models/return-method';
import { useViewRoute } from './useAppRoutes';
import { SelectedReturnMethodState, useGetSelectedReturnMethodDraft } from '../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { AppStateInterface, useGetRescheduleTimeWindows } from '../store/features/appStateSlice/appStateSlice';

type RescheduleType = SelectedReturnMethodState['draft']['reschedule'];

type RescheduleTimeWindowsType = AppStateInterface['rescheduleAvailableTimeWindows'];

export function handleSuccessfulReschedule(
  successfulRescheduleTimeWindowId: string,
  availableTimeWindows: AvailableTimeWindows,
) {
  const firstAvailableTimeWindowId = Object
    .keys(availableTimeWindows)
    .find((id) => id !== successfulRescheduleTimeWindowId);
  const {
    [successfulRescheduleTimeWindowId]: unusedValue,
    ...remainingTimeWindows
  } = availableTimeWindows;
  const lastSuccessfullyScheduledTimeWindow = availableTimeWindows[
    successfulRescheduleTimeWindowId];
  return { firstAvailableTimeWindowId, remainingTimeWindows, lastSuccessfullyScheduledTimeWindow };
}

export function processTimeWindows(
  isView: boolean,
  reschedule: RescheduleType,
  rescheduleTimeWindows: RescheduleTimeWindowsType,
) {
  type TimeWindowData = {
    firstAvailableTimeWindowId: string | undefined;
    lastSuccessfullyScheduledTimeWindow: TimeWindow | undefined;
    remainingTimeWindows: AvailableTimeWindows | undefined;
  };
  let timeWindowData: TimeWindowData = {
    firstAvailableTimeWindowId: undefined,
    lastSuccessfullyScheduledTimeWindow: undefined,
    remainingTimeWindows: undefined,
  };

  if (!reschedule || !isView) return timeWindowData;

  const originalTimeWindowId = reschedule.originalTimeWindow.timeWindowId;
  const { successfulRescheduleTimeWindowId } = reschedule;

  timeWindowData.firstAvailableTimeWindowId = Object.keys(
    rescheduleTimeWindows?.availableTimeWindows || {},
  ).find((id) => id !== originalTimeWindowId);
  timeWindowData.lastSuccessfullyScheduledTimeWindow = rescheduleTimeWindows?.availableTimeWindows[
    originalTimeWindowId
  ];
  const {
    [originalTimeWindowId]: ignoredValue,
    ...rest
  } = rescheduleTimeWindows?.availableTimeWindows || {};

  timeWindowData.remainingTimeWindows = rest;

  if (successfulRescheduleTimeWindowId) {
    const result = handleSuccessfulReschedule(
      successfulRescheduleTimeWindowId,
      timeWindowData.remainingTimeWindows,
    );
    timeWindowData = { ...timeWindowData, ...result };
  }

  return timeWindowData;
}

export function useHandleReschedules() {
  const isView = useViewRoute();
  const { reschedule } = useGetSelectedReturnMethodDraft();
  const rescheduleTimeWindows = useGetRescheduleTimeWindows();

  return processTimeWindows(isView, reschedule, rescheduleTimeWindows);
}
