import Button from '@ingka/button';
import ellipsesHorizontal from '@ingka/ssr-icon/paths/ellipses-horizontal';
import { useApplicableActions } from '../../../hooks/useApplicableActions';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { useGetSSUIUrl } from '../../../store/features/appStateSlice/appStateSlice';

export function ShowMoreMenu() {
  const { actions } = useApplicableActions();
  const selfServiceViewURL = useGetSSUIUrl();
  const goToMoreMenuPage = useGoToSidebarPage('more-menu');

  if (!actions && !selfServiceViewURL) return null;

  return (
    <Button
      className="col-start-3 col-end-4"
      data-testid="show-more"
      ssrIcon={ellipsesHorizontal}
      iconOnly
      type="secondary"
      onClick={goToMoreMenuPage}
      size="small"
    />
  );
}
