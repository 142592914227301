/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import { apiSlice } from '../api/apiSlice';

type ToastType = 'success' | 'error' | null;
type ToastState = {
  message: string; // Actual rendered string or translation-key
  type: ToastType;
  isVisible: boolean;
};

export const toastInitialState: ToastState = {
  message: '',
  type: null,
  isVisible: false,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState: toastInitialState,
  reducers: {
    showToast: (
      state: ToastState,
      action: PayloadAction<{ message: string; type: ToastType }>,
    ) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isVisible = true;
    },
    hideToast: (state: ToastState) => {
      state.isVisible = false;
      state.message = '';
      state.type = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(apiSlice.endpoints.submitReturn.matchFulfilled, (state) => {
        state.message = 'control-bar.toast.submitReturn.successTitle';
        state.type = 'success';
        state.isVisible = true;
      })
      .addMatcher(apiSlice.endpoints.submitReturn.matchRejected, (state) => {
        state.message = 'control-bar.toast.submitReturn.errorTitle';
        state.type = 'error';
        state.isVisible = true;
      })
      .addMatcher(apiSlice.endpoints.rescheduleReturn.matchFulfilled, (state) => {
        state.message = 'control-bar.toast.rescheduleReturn.successTitle';
        state.type = 'success';
        state.isVisible = true;
      })
      .addMatcher(apiSlice.endpoints.cancelReturn.matchFulfilled, (state) => {
        state.message = 'control-bar.toast.cancelReturn.successTitle';
        state.type = 'success';
        state.isVisible = true;
      })
      .addMatcher(apiSlice.endpoints.getReturnAuthorizationProducts.matchRejected, (state) => {
        state.message = 'control-bar.toast.getReturnAuthorizationProducts.errorTitle';
        state.type = 'error';
        state.isVisible = true;
      })
      .addMatcher(apiSlice.endpoints.getProductInfo.matchRejected, (state) => {
        state.message = 'control-bar.toast.getProductInfo.errorTitle';
        state.type = 'error';
        state.isVisible = true;
      });
  },
});

export default toastSlice.reducer;

// convenience selectors and actions
export const useGetToast = () => useLamnaSelector((state) => state.toast);

export const useToast = () => {
  const dispatch = useLamnaDispatch();
  const showToast = (message: string, type: ToastType) => {
    dispatch(toastSlice.actions.showToast({ message, type }));
  };
  const hideToast = () => {
    dispatch(toastSlice.actions.hideToast());
  };
  return { showToast, hideToast };
};
