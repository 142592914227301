import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useApplicableActions } from '../../hooks/useApplicableActions';
import useAvailableActionsConfig from '../../hooks/useAvailableActionsConfig/useAvailableActionsConfig';
import { ViewModeApplicableActions } from '../../models';

export function FailedStepActions() {
  const { t } = useTranslation();
  const { actions } = useApplicableActions();
  const actionsConfig = useAvailableActionsConfig();

  if (!actions) return null;

  const mapActionsToLabel = (action: ViewModeApplicableActions) => {
    if (action === 'RE_SCHEDULE' || action === 'RE_SCHEDULE_FAILED') {
      return t('show-more.actions.re_schedule');
    }

    return t('show-more.actions.cancel');
  };

  return (
    <InlineMessage
      body={(
        <span className="flex flex-wrap">
          {actions.map((action, index) => (
            <Fragment key={action}>
              <Button
                className="!font-normal underline [&_span]:!min-h-0"
                onClick={actionsConfig[action]}
                text={mapActionsToLabel(action)}
                type="plain"
              />
              {index < (actions.length - 1) && (
                <span className="px-4 h-4 relative top-1 left-4 border-l text-textAndIcon-2" />
              )}
            </Fragment>
          ))}
        </span>
      )}
      className="my-4 m:max-w-fit"
      data-testid="failed-step-actions"
      title={t('statusViewModel.actions.title')}
      ssrIcon={incorrect}
      variant="negative"
    />
  );
}
