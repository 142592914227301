import type { PayloadAction } from '@reduxjs/toolkit';
import { ItemAdded, PickedItem } from '@resolutions/item-picker-contract';
import { PopPlugin } from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import { GetProductsResponse } from '../api/returnAuthorization/products';

export type BlindReturnItemFetching = {
  isFetching: boolean;
  itemNo: string;
  quantity: number;
};
export type BlindReturn = {
  checked: boolean;
  items: (BlindReturnItemFetching | PoPSelectedItem)[];
};
export type PoPSelectedItem = PickedItem & { checked: boolean };

export const isPoPSelectedItem = (
  item: BlindReturnItemFetching | PoPSelectedItem,
): item is PoPSelectedItem => !('isFetching' in item);

export type StatePop = {
  checked: boolean;
  fetchedResponse: GetProductsResponse | null;
  id: string;
  isFetching: boolean;
  selectedItems: PoPSelectedItem[];
  type: PopPlugin;
};

export type OTCState = {
  actionsSidebarPopId: string | null;
  articleInSidebar: PoPSelectedItem | null;
  blindReturn: BlindReturn;
  pops: StatePop[];
};

export const updateSelectedItemsForPop = (state: OTCState, action: PayloadAction<ItemAdded>) => {
  const { item, purchaseId } = action.payload;
  state.pops = state.pops.map((pop) => {
    let itemInExistingPopSelectedItems = false;
    if (pop.id !== purchaseId) return pop;

    let updatedPop = {
      ...pop,
      selectedItems: pop.selectedItems.map((popItem) => {
        if (popItem.itemNo !== item.itemNo
          || popItem.deliveryReference !== item.deliveryReference) {
          return popItem;
        }

        itemInExistingPopSelectedItems = true;
        return {
          ...popItem,
          quantity: popItem.quantity + item.quantity,
        };
      }),
    };

    if (!itemInExistingPopSelectedItems) {
      updatedPop = {
        ...pop,
        selectedItems: [
          ...pop.selectedItems,
          { ...item, checked: false }],
      };
    }

    return updatedPop;
  });
};

export const filterBlindReturnItemsByItemNo = (
  state: OTCState,
  filterItemNo: string | undefined,
) => {
  if (!filterItemNo) return;

  const updatedBlindReturnItems = state.blindReturn.items.filter(
    (item) => item.itemNo !== filterItemNo,
  );
  state.blindReturn.items = updatedBlindReturnItems;
};
