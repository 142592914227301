import Button from '@ingka/button';
import Hyperlink from '@ingka/hyperlink';
import { ModalBody } from '@ingka/modal';
import ProductIdentifier from '@ingka/product-identifier';
import copyIcon from '@ingka/ssr-icon/paths/copy';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useGetArticleInSidebar } from '../../../store/features/OTCSlice/OTCSlice';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { formatProductNumber } from '../../../utils/productNumber';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { PoPSelectedItem } from '../../../store/features/OTCSlice/reducerUtils';

export const createLinks = (
  article: PoPSelectedItem,
  _countryCode: string,
  _languageCode: string,
) => {
  const { itemNo, itemType, name } = article;
  const countryCode = _countryCode.toLowerCase();
  const languageCode = _languageCode.toLowerCase();

  const createIkeaWebLink = () => {
    const topLevelDomain = countryCode === 'cn' ? '.cn' : '.com';
    const prefixForSpr = itemType === 'SPR' ? 's' : '';
    return `https://www.ikea${topLevelDomain}/${countryCode}/${languageCode}/p/-${prefixForSpr}${itemNo}`;
  };

  const links = [
    {
      text: 'Sälja pro',
      url: `https://salja.ingka.com/${countryCode}/${languageCode}/item/?q=${itemNo}&t=${itemType}`,
    },
    {
      text: 'VETA',
      url: `https://km.ingka.com/${countryCode}/${languageCode}/search?query=${encodeURIComponent(name)}`,
    },
    {
      text: 'Pia Facts',
      url: `https://piafacts.ikea.net/facts/${itemNo}?l=${languageCode}-${countryCode}`,
    },
    {
      text: 'Ikea.com',
      url: createIkeaWebLink(),
    },
  ];
  return links;
};

function QuickLinks() {
  const { t } = useTranslation();
  const article = useGetArticleInSidebar();
  if (!article) return null;

  const { countryCode, languageCode } = useMarketLocale();
  const linksInformation = createLinks(article, countryCode, languageCode);

  return (
    <>
      <Text className="mt-7 !text-dark font-bold" id="quicklinks">{t('otc-article-viewer-sidebar.quickLinks')}</Text>
      <ul aria-labelledby="quicklinks" className="flex gap-2 my-1">
        {linksInformation.map(({ text, url }) => (
          <li key={text} className="border-r-[1px] pr-2 last:border-r-0">
            <Hyperlink text={text} url={url} newWindow />
          </li>
        ))}
      </ul>
    </>
  );
}
export function OTCArticleViewerBody() {
  const { t } = useTranslation();
  const article = useGetArticleInSidebar();

  if (!article) return null;

  const formattedArticleNumber = formatProductNumber(article.itemNo);

  return (
    <ModalBody className="!pt-0">
      <div className="px-5">
        <img
          alt={article.productImage?.alt ?? ''}
          className="w-full"
          src={article.productImage?.url.replace('s2.', 's4.')}
        />
      </div>
      <Text className="!text-dark uppercase font-bold mt-4">{article.name}</Text>
      <Text className="my-1">{article.description}</Text>
      <div className="flex gap-2 items-center">
        <ProductIdentifier value={formattedArticleNumber} subtle />
        <Button
          aria-label={t('otc-article-viewer-sidebar.copyArticleNumber')}
          className="[&_span]:!min-h-0 [&_span]:!p-0 [&_svg]:h-5 [&_svg]:w-5"
          iconOnly
          onClick={() => copyToClipboard(formattedArticleNumber)}
          size="xsmall"
          ssrIcon={copyIcon}
          type="tertiary"
        />
      </div>
      <QuickLinks />
    </ModalBody>
  );
}
