import { ArticleListItemProps } from './types';

export type GroupedProducts = {
  [key: string]: {
    groupTranslation: string;
    groupTooltipTranslation: string | null;
    orderNumber?: string;
    products: ArticleListItemProps[];
    receiptNumber?: string;
  };
};

const BLIND_RETURN_GROUP = 'blindReturn';

const getGroupTranslation = (orderNumber?: string, receiptNumber?: string) => {
  if (orderNumber && receiptNumber) return 'orderAndReceiptNumber';
  if (orderNumber) return 'orderNumber';
  if (receiptNumber) return 'receiptNumber';
  return BLIND_RETURN_GROUP;
};

export const reduceGroupedProducts = (
  acc: GroupedProducts,
  product: ArticleListItemProps,
) => {
  let orderNumber;
  let receiptNumber;

  if ('orderReference' in product) {
    orderNumber = product.orderReference?.orderNumber;
  }
  if ('receiptReference' in product) {
    receiptNumber = product.receiptReference?.ctmDocumentId;
  }

  let key = BLIND_RETURN_GROUP;
  if (orderNumber || receiptNumber) {
    key = `${orderNumber ?? ''}.${receiptNumber ?? ''}`;
  }
  const groupTranslation = getGroupTranslation(orderNumber, receiptNumber);
  const groupProducts = acc[key] ?? {
    groupTooltipTranslation: groupTranslation === BLIND_RETURN_GROUP ? null : 'articles-reason-card.groups.orderNumberTooltip',
    groupTranslation,
    orderNumber,
    products: [],
    receiptNumber,
  };
  groupProducts.products.push(product);

  return {
    ...acc,
    [key]: groupProducts,
  };
};
