import { ModalBody } from '@ingka/modal';
import { formatToTime, formatToLongDate } from 'shared-frontend';
import Text from '@ingka/text';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import InlineMessage from '@ingka/inline-message';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { HistoryLogViewModelEntry } from '../../../models/HistoryLogResponse';
import { useGetHistoryLogsQuery } from '../../../store/features/api/apiSlice';
import { EntriesSkeleton } from '../EntriesSkeleton';
import { useGetReturnId } from '../../../store/features/appStateSlice/appStateSlice';

type HistoryLog = HistoryLogViewModelEntry & { createdAtDate: Date; };

function HistoryLogEntry({ entry: { title, createdAtDate, details } }: { entry: HistoryLog }) {
  const { languageCode, countryCode } = useMarketLocale();
  const dateMonth = formatToLongDate({ date: createdAtDate, languageCode, countryCode });
  const time = formatToTime({ date: createdAtDate, languageCode, countryCode });

  return (
    <section className="flex py-4 px-6 min-h-11 gap-6">
      <div className="text-right flex-grow-0 basis-11">
        <time dateTime={`${dateMonth}, ${time}`}>
          <span className="font-bold">
            {dateMonth}
          </span>
          {' '}
          <span>
            {time}
          </span>
        </time>
      </div>
      <div className="flex-grow">
        <Text tagName="h3" headingSize="xs">{title}</Text>
        {details.map((detail) => (
          <p key={detail}>
            {detail}
          </p>
        ))}
      </div>
    </section>
  );
}

export function HistoryLogBody() {
  const { t } = useTranslation();
  const { countryCode } = useMarketLocale();
  const returnId = useGetReturnId();
  const {
    data: historyLog,
    isLoading,
    isError,
  } = useGetHistoryLogsQuery({
    countryCode,
    returnId: returnId!,
  }, {
    skip: !returnId,
  });
  const logs = historyLog?.historyLogViewModel;
  const dayGroups = logs?.reduce<HistoryLog[][]>((groups, entry, index) => {
    const item = { ...entry, createdAtDate: new Date(entry.createdAt) };
    const currentDate = item.createdAtDate.getDate();
    if (
      index === 0
      || currentDate !== new Date(groups[groups.length - 1][0].createdAt).getDate()
    ) {
      groups.push([item]);
    } else {
      groups[groups.length - 1].push(item);
    }

    return groups;
  }, []) || [];

  if (isLoading) {
    return (
      <ModalBody>
        <EntriesSkeleton />
      </ModalBody>
    );
  }
  if (isError || logs?.length === 0) {
    const message = isError ? t('commonly-reused.errorMessage') : t('historyLogViewModel.noDataMessage');
    const variant = isError ? 'negative' : 'informative';
    return (
      <ModalBody>
        <InlineMessage
          subtle
          variant={variant}
          ssrIcon={informationCircle}
          body={message}
          className="mt-4"
        />
      </ModalBody>
    );
  }

  return (
    <ModalBody className="p-[0_!important]">
      {dayGroups.map((group, index) => (
        <Fragment key={group[0].createdAtDate.toISOString()}>
          <article>
            {group.map((item) => (
              <HistoryLogEntry key={item.createdAt} entry={item} />
            ))}
          </article>
          {index !== dayGroups.length - 1 && <hr className="border-t-neutral-grey-200 border-t-3 mb-0 mt-0 mx-5" />}
        </Fragment>
      ))}
    </ModalBody>
  );
}
