import { createAction } from '@reduxjs/toolkit';
import { CustomerReturn } from '../../../models';
import { PoPSelectedItem } from '../OTCSlice/reducerUtils';

// Added here instead of in ./actions due to dependency cycle issue
export type SidebarPageKey =
  'return-method'
  | 'time-window-calendar'
  | 'questionnaire'
  | 'more-menu'
  | 'history-log'
  | 'otc-search'
  | 'otc-pop-actions'
  | 'otc-article-viewer'
  | 'otc-article-actions'
  | 'otc-blind-return-article-actions';

export const timeWindowSet = createAction<{
  hasCommittedMethod: boolean
  timeWindowHasChangedInViewMode: boolean
}>('timeWindowSet');

export type ArticlesWithIncorrectUnit = {
  name?: string;
  productNumber?: string;
}[] | null;
export const articlesWithIncorrectUnit = createAction<ArticlesWithIncorrectUnit>(
  'articlesWithIncorrectUnit',
);

export type InitialCreateModePayload = {
  accessToken: string;
  customerReturn: CustomerReturn | null;
  isAuthenticated: boolean;
  userId: string | null;
};
export const setInitialCreateMode = createAction<InitialCreateModePayload>(
  'setInitialCreateMode',
);
export const removePoP = createAction('removePoP');

export const showOTCArticleSidebar = createAction<{
  article: PoPSelectedItem,
  sidebarPage: SidebarPageKey,
}>('showOTCArticleSidebar');

export const removeSelectedArticlesPop = createAction('remove-selected-article-pop');
export const removeArticle = createAction('remove-article');
export const removeBlindReturnArticle = createAction('remove-blind-return-article');
