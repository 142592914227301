import ListView, { ListViewItem } from '@ingka/list-view';
import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import { useGoToPromptPage } from '../../../store/features/prompt/promptSlice';

export function OTCBlindReturnArticleActionsBody() {
  const goToPromptRemoveArticle = useGoToPromptPage('otc-remove-blind-return-article');
  const { t } = useTranslation();

  return (
    <ModalBody>
      <ListView
        id="articleActions"
        showDivider
      >
        <ListViewItem
          className="hover:bg-neutral-grey-100 [&_svg]:hidden"
          control="navigational"
          emphasised
          inset
          key="REMOVE_ARTICLE"
          onClick={() => goToPromptRemoveArticle()}
          title={t('prompt-actions.confirm-remove-article.title')}
        />
      </ListView>

    </ModalBody>
  );
}
