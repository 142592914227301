import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { PriceItem } from 'shared-frontend';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { useRefundCalculations } from '../../../store/features/api/refundCalc/refundCalcHooks';
import { useGetCustomerReturn } from '../../../store/features/appStateSlice/appStateSlice';

type EstimatedRefundBodyProps = {
  languageCode: string;
  countryCode: string;
  refund: any;
  label: string;
  currencyCode: string;
};

export function EstimatedRefundBody(
  {
    languageCode, countryCode, refund, label, currencyCode,
  }: EstimatedRefundBodyProps,
) {
  return (
    <>
      <Text tagName="span" className="font-bold !text-dark">
        {label}
      </Text>
      <Text tagName="span">
        {refund ? (
          <PriceItem
            locale={`${languageCode}-${countryCode}`}
            currencyCode={currencyCode}
            amount={refund}
            size="medium"
          />
        ) : '-'}
      </Text>
    </>
  );
}

export function RefundNotAvailableBody() {
  const { t } = useTranslation();
  return (
    <Text tagName="span" className="font-bold !text-dark">
      {t('control-bar.estimatedRefundNotAvailable')}
    </Text>
  );
}

export function EstimatedRefund() {
  const { t } = useTranslation();
  const { languageCode, countryCode } = useMarketLocale();
  const refund = useRefundCalculations();
  const { exchangeResolution } = useGetCustomerReturn() ?? {};

  if (exchangeResolution) {
    return <RefundNotAvailableBody />;
  }

  return (
    <EstimatedRefundBody
      languageCode={languageCode}
      countryCode={countryCode}
      refund={refund?.estimatedRefund}
      label={t('control-bar.estimatedRefund')}
      currencyCode={refund?.currencyCode || ''}
    />
  );
}
