import Accordion, { AccordionItem } from '@ingka/accordion';
import InlineMessage from '@ingka/inline-message';
import Loading, { LoadingBall } from '@ingka/loading';
import Text from '@ingka/text';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { Card, useScrollIntoView } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import ArticleListItem from './ArticleListItem/ArticleListItem';
import {
  GroupedProducts,
  reduceGroupedProducts,
} from './reduceGroupedProducts';
import { useProductInformation } from './useProductInformation';
import { useGetShowStockCorrection } from '../../store/features/appStateSlice/appStateSlice';
import TooltipWithCondition from '../Tooltips/TooltipWithCondition';

function ArticlesReasonCard() {
  const { t } = useTranslation();
  const showStockCorrections = useGetShowStockCorrection();
  const cardRef = useRef<HTMLDivElement>(null);

  useScrollIntoView(cardRef, showStockCorrections, {
    block: 'start',
  });

  const { enrichedProducts, isError, isLoading } = useProductInformation();
  const groupedProducts = enrichedProducts?.reduce<GroupedProducts>(reduceGroupedProducts, {});
  const showLoader = isLoading || !groupedProducts;

  return (
    <Card
      cardRef={cardRef}
      className="pb-0 mx-0"
      title={(
        <Text className="px-2 m:px-6 l:px-10" headingSize="m">
          <div className="px-2">{t('articles-reason-card.title')}</div>
        </Text>
      )}
      horizontalPadding={false}
    >
      {isError && (
        <InlineMessage
          body={t('articles-reason-card.errorFetchingProductInfo')}
          className="mx-2 m:mx-6 l:mx-10 mb-6 w-fit"
          ssrIcon={informationCircle}
          variant="negative"
        />
      )}
      {showLoader ? (
        <Loading data-testid="loader" text={t('commonly-reused.loading')}>
          <LoadingBall />
        </Loading>
      ) : (
        <Accordion collapsible={false}>
          {Object.keys(groupedProducts).map((group) => {
            const {
              groupTranslation, groupTooltipTranslation, orderNumber, products, receiptNumber,
            } = groupedProducts[group];
            const translationOptions = { orderNumber, receiptNumber };

            return (
              <AccordionItem
                className={`[&_.accordion-item-header]:px-2 m:[&_.accordion-item-header]:px-4
                [&_.accordion-item-header]:overflow-visible l:[&_.accordion-item-header]:px-10 [&_.accordion-item-header]:py-0
                 [&_.accordion-item-header]:bg-neutral-grey-100 w-full [&_div[role='region']]:p-0`}
                id={group}
                key={group}
                open
                size="small"
                title={(
                  <TooltipWithCondition tooltipText={groupTooltipTranslation}>
                    <Text className="pl-2" headingSize="s">
                      {t(`articles-reason-card.groups.${groupTranslation}`, translationOptions)}
                    </Text>
                  </TooltipWithCondition>
                )}
                ariaLevel={3}
              >
                {products.map((article) => (
                  <ArticleListItem
                    key={article.id}
                    article={article}
                  />
                ))}
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </Card>

  );
}

export default ArticlesReasonCard;
