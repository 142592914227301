import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { getQueryParams } from 'shared-frontend';
import config from '../../../config';
import { consumer } from './apiConfig';
import { returnMethodsBuilder } from './returnMethods/returnMethod';
import { questionnaireBuilder } from './questionnaire/questionnaire';
import { marketConfigBuilder } from './marketConfig/marketConfig';
import { submitReturnBuilder } from './submitReturn/submitReturn';
import { refundCalcBuilder } from './refundCalc/refundCalc';
import { getCustomerReturnBuilder } from './getCustomerReturn/getCustomerReturn';
import { cancelReturnBuilder } from './cancelReturn/cancelReturn';
import { rescheduleReturnBuilder } from './rescheduleReturn/rescheduleReturn';
import { getAvailableTimeWindowsBuilder } from './getAvailableTimeWindows/getAvailableTimeWindows';
import { getProductInfoBuilder } from './productInfo/getProductInfo';
import type { RootState } from '../../app/store';
import { getCountryAndLanguageFromUrl } from '../../../i18n';
import { getHistoryLogsBuilder } from './historyLog/historyLog';
import { getReturnAuthorizationProductsBuilder } from './returnAuthorization/products';

const endpointsWithAuth = [
  'submitReturn',
  'getCustomerReturn',
  'cancelReturn',
  'rescheduleReturn',
  'getHistoryLogs',
  'getReturnAuthorizationProducts',
];

const endpointsWithAcceptLanguage = [
  'submitReturn',
  'getCustomerReturn',
  'cancelReturn',
  'getHistoryLogs',
];

// we wrap RTK fetchBaseQuery in order to fetch config and dynamically set the path
const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  WebApi,
  extraOptions,
) => {
  await config();
  const baseUrl = window.config.VITE_RETURNS_URL;
  const rawBaseQuery = await retry(fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('x-consumer', consumer);

      if ('beTestMode' in getQueryParams()) {
        headers.set('test-mode', 'true');
      }

      if ('dryRun' in getQueryParams() && endpoint === 'submitReturn') {
        headers.set('dry-run', 'true');
      }

      if (endpointsWithAcceptLanguage.includes(endpoint)) {
        const [country, lang] = getCountryAndLanguageFromUrl();
        headers.set('Accept-Language', `${lang.toLowerCase()}-${country.toUpperCase()}`);
      }

      if (endpointsWithAuth.includes(endpoint)) {
        const storeState = getState() as RootState;
        headers.set('Authorization', `Bearer ${storeState.appState.accessToken}`);
      }

      return headers;
    },
  }), {
    maxRetries: process.env.NODE_ENV === 'test' ? 0 : 1,
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

export type AppEndPointBuilder = EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, never, 'api'>;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  keepUnusedDataFor: 9999,
  endpoints: (builder) => ({
    marketConfig: marketConfigBuilder(builder),
    availableReturnMethods: returnMethodsBuilder(builder),
    questionnaire: questionnaireBuilder(builder),
    submitReturn: submitReturnBuilder(builder),
    refundCalc: refundCalcBuilder(builder),
    getCustomerReturn: getCustomerReturnBuilder(builder),
    cancelReturn: cancelReturnBuilder(builder),
    rescheduleReturn: rescheduleReturnBuilder(builder),
    getAvailableTimeWindows: getAvailableTimeWindowsBuilder(builder),
    getProductInfo: getProductInfoBuilder(builder),
    getHistoryLogs: getHistoryLogsBuilder(builder),
    getReturnAuthorizationProducts: getReturnAuthorizationProductsBuilder(builder),
  }),
});

export const {
  useAvailableReturnMethodsQuery,
  useQuestionnaireQuery,
  useMarketConfigQuery,
  useRefundCalcQuery,
  useSubmitReturnMutation,
  useGetCustomerReturnQuery,
  useCancelReturnMutation,
  useRescheduleReturnMutation,
  useGetAvailableTimeWindowsQuery,
  useGetProductInfoQuery,
  useLazyGetProductInfoQuery,
  useGetHistoryLogsQuery,
  useGetReturnAuthorizationProductsQuery,
  useLazyGetReturnAuthorizationProductsQuery,
} = apiSlice;
