import Text from '@ingka/text';
import pencilIcon from '@ingka/ssr-icon/paths/pencil';
import { useTranslation } from 'react-i18next';
import { DataCard } from 'shared-frontend';
import Button from '@ingka/button';
import { useReturnMethodConfig } from './useReturnMethodConfig';
import { useGoToSidebarPage } from '../../store/features/sidebar/sidebarSlice';
import { CreateRouteOnly } from '../RouteOnly/RouteOnly';

export type ReturnMethodData = {
  name: string,
  description: string,
  confirmationInfo: string,
  selectedTime?: string,
  selectedTimeDescription?: string,
};

function ReturnMethodCard(): React.ReactElement {
  const { t } = useTranslation();
  const openSidebar = useGoToSidebarPage('return-method');

  const {
    config,
    content,
    editDisabled,
    editLoading,
    selectedReturnMethod,
  } = useReturnMethodConfig();

  return (
    <DataCard
      className="text-sm"
      data-testid="return-method-card"
      cardButton={(
        <CreateRouteOnly>
          <Button
            disabled={editDisabled}
            loading={editLoading}
            ssrIcon={pencilIcon}
            iconPosition="trailing"
            text={t('commonly-reused.edit')}
            type={!selectedReturnMethod ? 'primary' : 'secondary'}
            onClick={openSidebar}
            size="small"
          />
        </CreateRouteOnly>
      )}
      content={content}
      config={config}
      title={
        <Text tagName="h2" headingSize="m">{t('return-method-card.title')}</Text>
      }
    />
  );
}

export default ReturnMethodCard;
