import { useMarketLocale } from './hooks/useMarketLocale';
import { useMarketConfigQuery } from './store/features/api/apiSlice';
import type { MarketConfig } from './store/features/api/marketConfig/marketConfig';

export const useMarketConfigFetch = () => {
  const { countryCode } = useMarketLocale();

  const marketConfigResponse = useMarketConfigQuery({
    countryCode: countryCode.toLowerCase() as Lowercase<string>,
  }, { skip: !countryCode });

  const { data: marketConfig } = marketConfigResponse;
  const marketConfigWithDefaults: MarketConfig | undefined = marketConfig
    ? {
      // @ts-ignore: purchaseInformation can be updated by marketConfig response
      purchaseInformation: {},
      ...marketConfig,
    } : undefined;

  return {
    ...marketConfigResponse,
    data: marketConfigWithDefaults,
  };
};
