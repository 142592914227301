import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SuccessfulScan } from '@resolutions/scanner-contract';
import { ItemAdded } from '@resolutions/item-picker-contract';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import {
  IN_STORE_POP_NAME, ONLINE_POP_NAME, PopId,
} from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import {
  setActionsSidebarPop,
} from '../standAloneActions/actions';
import {
  filterBlindReturnItemsByItemNo,
  OTCState,
  PoPSelectedItem,
  StatePop,
  updateSelectedItemsForPop,
} from './reducerUtils';
import { closeSidebar } from '../sidebar/sidebarSlice';
import {
  removeArticle,
  removeBlindReturnArticle,
  removePoP,
  removeSelectedArticlesPop,
  showOTCArticleSidebar,
  SidebarPageKey,
} from '../standAloneActions/extraReducersActions';
import { apiSlice } from '../api/apiSlice';

export type PopSuccessfulScan = SuccessfulScan<PopId>;

export const OTCInitialState: OTCState = {
  actionsSidebarPopId: null,
  articleInSidebar: null,
  blindReturn: { checked: false, items: [] },
  pops: [],
};

export const OTCSlice = createSlice({
  name: 'OTC',
  initialState: OTCInitialState,
  reducers: {
    updateSelectedItemsForPop,
    toggleBlindReturnAllChecked: (state) => {
      state.blindReturn = {
        checked: !state.blindReturn.checked,
        items: state.blindReturn.items.map((item) => ({
          ...item,
          checked: !state.blindReturn.checked,
        })),
      };
    },
    toggleBlindReturnItemChecked: (state, action: PayloadAction<{ itemNo: string }>) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (item.itemNo !== action.payload.itemNo) return item;

        return {
          ...item,
          checked: !(item as PoPSelectedItem).checked,
        };
      });
    },
    increaseBlindReturnItemQuantity: (state, action: PayloadAction<{ itemNo: string }>) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (item.itemNo !== action.payload.itemNo) return item;

        return {
          ...item,
          quantity: item.quantity + 1,
        };
      });
    },
    togglePopChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string }>,
    ) => {
      const { popId } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        return {
          ...pop,
          selectedItems: pop.selectedItems.map((selectedItem) => ({
            ...selectedItem,
            checked: !pop.checked,
          })),
          checked: !pop.checked,
        };
      });
    },
    togglePopSelectedItemsChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string, itemNo: string }>,
    ) => {
      const { popId, itemNo } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        const { selectedItems } = pop;
        const item = selectedItems.find((selectedItem) => selectedItem.itemNo === itemNo);
        if (!item) return pop;
        return {
          ...pop,
          selectedItems: selectedItems.map((selectedItem) => {
            if (selectedItem.itemNo !== itemNo) return selectedItem;
            return {
              ...selectedItem,
              checked: !selectedItem.checked,
            };
          }),
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        showOTCArticleSidebar,
        (
          state: OTCState,
          action: PayloadAction<{
            article: PoPSelectedItem,
            sidebarPage: SidebarPageKey,
          }>,
        ) => {
          state.articleInSidebar = action.payload.article;
        },
      )
      .addCase(
        setActionsSidebarPop,
        (state: OTCState, action: PayloadAction<{ popId: string | null }>) => {
          state.actionsSidebarPopId = action.payload.popId;
        },
      )
      .addCase(closeSidebar, (state: OTCState, action: PayloadAction<SidebarPageKey>) => {
        if (action.payload === 'otc-pop-actions') {
          state.actionsSidebarPopId = null;
        }
        if (action.payload === 'otc-article-viewer'
          || action.payload === 'otc-article-actions'
          || action.payload === 'otc-blind-return-article-actions'
        ) {
          state.articleInSidebar = null;
        }
      })
      .addCase(removePoP, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        state.pops = state.pops.filter((pop) => pop.id !== popId);
        state.actionsSidebarPopId = null;
      })
      .addCase(removeArticle, (state: OTCState) => {
        const articleToRemove = state.articleInSidebar;
        state.pops = state.pops.map((pop) => {
          const updatedPop = { ...pop };
          updatedPop.selectedItems = pop.selectedItems.filter(
            (item) => item.itemNo !== articleToRemove?.itemNo,
          );
          return updatedPop;
        });
      })
      .addCase(removeBlindReturnArticle, (state) => {
        const articleToRemove = state.articleInSidebar;
        filterBlindReturnItemsByItemNo(state, articleToRemove?.itemNo);
      })
      .addCase(removeSelectedArticlesPop, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        const filterSelectedItems = (pop: StatePop) => {
          if (pop.id === popId) {
            const filteredItems = pop.selectedItems.filter((item) => item.checked === false);
            return {
              ...pop,
              selectedItems: filteredItems,
            };
          }
          return pop;
        };
        state.pops = state.pops.map(filterSelectedItems);
        state.actionsSidebarPopId = null;
      })
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchPending,
        (state, action) => {
          const { purchaseId } = action.meta.arg.originalArgs;
          const inStoreRegex = /\d{18}|\d{22}/;

          state.pops = [
            ...state.pops,
            {
              checked: false,
              fetchedResponse: null,
              id: action.meta.arg.originalArgs.purchaseId,
              isFetching: true,
              selectedItems: [],
              type: purchaseId.match(inStoreRegex) ? IN_STORE_POP_NAME : ONLINE_POP_NAME,
            },
          ];
        },
      )
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchRejected,
        (state, action) => {
          const { purchaseId } = action.meta.arg.originalArgs;
          const updatedPops = state.pops.filter((pop) => pop.id !== purchaseId);
          state.pops = updatedPops;
        },
      )
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchFulfilled,
        (state, action) => {
          const { purchaseId } = action.meta.arg.originalArgs;
          const updatedPops = state.pops.map((pop) => {
            if (pop.id !== purchaseId) return pop;

            return {
              ...pop,
              fetchedResponse: action.payload,
              isFetching: false,
            };
          });
          state.pops = updatedPops;
        },
      )
      .addMatcher(apiSlice.endpoints.getProductInfo.matchPending, (state, action) => {
        const { productNumbers: itemNo } = action.meta.arg.originalArgs;
        state.blindReturn.items = [
          ...state.blindReturn.items,
          {
            isFetching: true,
            itemNo,
            quantity: 1,
          },
        ];
      })
      .addMatcher(
        apiSlice.endpoints.getProductInfo.matchRejected,
        (state, action) => {
          const { productNumbers } = action.meta.arg.originalArgs;
          filterBlindReturnItemsByItemNo(state, productNumbers);
        },
      )
      .addMatcher(
        apiSlice.endpoints.getProductInfo.matchFulfilled,
        (state, action) => {
          const { productNumbers } = action.meta.arg.originalArgs;
          const [productInResponse] = action.payload;
          const updatedBlindReturnItems = state.blindReturn.items.map(
            (item) => {
              if (item.itemNo !== productNumbers) return item;

              return {
                checked: state.blindReturn.checked,
                currencyCode: '', // TODO: fix when we get price from BE
                deliveryReference: '0',
                description: productInResponse.type,
                dimensions: null,
                itemLineId: `${state.blindReturn.items.length}`,
                itemNo: item.itemNo,
                itemType: productInResponse.itemType,
                name: productInResponse.name,
                price: { // TODO: fix the price-object when we get price from BE
                  total: {
                    paidPrice: {
                      priceExclTax: null,
                      priceInclTax: null,
                    },
                  },
                  unit: {
                    paidPrice: {
                      priceExclTax: null,
                      priceInclTax: null,
                    },
                  },
                },
                productImage: {
                  alt: productInResponse.media?.alt,
                  url: productInResponse.media?.variants.S2,
                },
                quantity: item.quantity,
              } as PoPSelectedItem;
            },
          );
          state.blindReturn.items = updatedBlindReturnItems;
        },
      );
  },
});

export default OTCSlice.reducer;

// convenience selectors
export const useGetPops = () => useLamnaSelector((state) => state.otc.pops);
export const useGetAllSelectedItemsFromPops = () => {
  const pops = useGetPops();
  return pops.flatMap((pop) => pop.selectedItems);
};
export const useGetSelectedItemsForPop = (popId: string | null = '') => {
  const matchingPop = useLamnaSelector(
    (state) => state.otc.pops.find((pop) => pop.id === popId),
  );
  return matchingPop?.selectedItems.filter((item) => item.checked);
};

// convenience dispatchers
export const useTogglePopChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string) => {
    dispatch(OTCSlice.actions.togglePopChecked({ popId }));
  };
};

export const useTogglePopSelectedItemsChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string, itemNo: string) => {
    dispatch(OTCSlice.actions.togglePopSelectedItemsChecked({ popId, itemNo }));
  };
};
export const useUpdateSelectedItemsForPop = () => {
  const dispatch = useLamnaDispatch();
  return (itemAdded: ItemAdded) => {
    dispatch(OTCSlice.actions.updateSelectedItemsForPop(itemAdded));
  };
};
export const useGetPop = (purchaseId: string) => {
  const matchingPop = useLamnaSelector(
    (state) => state.otc.pops.find((pop) => pop.id === purchaseId),
  );
  return matchingPop;
};
export const useGetArticleInSidebar = () => useLamnaSelector(
  (state) => state.otc.articleInSidebar,
);
export const useGetPoPForActionsSidebar = () => useLamnaSelector(
  (state) => state.otc.actionsSidebarPopId,
);
export const useGetBlindReturnArticleForActionsSidebar = () => useLamnaSelector(
  (state) => state.otc.articleInSidebar,
);
export const useGetBlindReturn = () => useLamnaSelector(
  (state) => state.otc.blindReturn,
);

export const useToggleBlindReturnAllChecked = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(OTCSlice.actions.toggleBlindReturnAllChecked());
  };
};
export const useToggleBlindReturnItemChecked = () => {
  const dispatch = useLamnaDispatch();
  return (itemNo: string) => {
    dispatch(OTCSlice.actions.toggleBlindReturnItemChecked({ itemNo }));
  };
};
export const useIncreaseBlindReturnItemQuantity = () => {
  const dispatch = useLamnaDispatch();
  return (itemNo: string) => {
    dispatch(OTCSlice.actions.increaseBlindReturnItemQuantity({ itemNo }));
  };
};
