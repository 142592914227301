import Text from '@ingka/text';
import { useGetArticlesWithIncorrectUnit } from '../../store/features/appStateSlice/appStateSlice';

export default function ArticlesWithIncorrectUnitBody() {
  const articlesWithIncorrectUnit = useGetArticlesWithIncorrectUnit();

  if (!articlesWithIncorrectUnit) {
    return null;
  }

  return (
    <ul>
      {articlesWithIncorrectUnit.map((article) => (
        <li key={article.productNumber}>
          <Text>{`${article.name} ${article.productNumber}`}</Text>
        </li>
      ))}
    </ul>
  );
}
