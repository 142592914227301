import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import Button from '@ingka/button';
import { useGetSelectedReturnMethodDraft } from '../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useCloseSidebar } from '../../store/features/sidebar/sidebarSlice';
import { useQuestionnaireForReturnMethod } from '../../store/features/api/questionnaire/hooks';
import { useReturnMethodSaved } from '../../store/features/standAloneActions/actions';

export function ReturnMethodPageFooter() {
  const { t } = useTranslation();

  const selectedReturnMethodDraft = useGetSelectedReturnMethodDraft().selectedReturnMethod;
  const questionnaire = useQuestionnaireForReturnMethod(selectedReturnMethodDraft?.id);
  const returnMethodSaved = useReturnMethodSaved();
  const close = useCloseSidebar();

  const text = questionnaire ? t('commonly-reused.continue') : t('commonly-reused.save');

  return (
    <ModalFooter className="modal-footer--dual-action" renderBorder>
      <Button
        text={t('commonly-reused.cancel')}
        onClick={close}
      />
      <Button
        type="primary"
        disabled={!selectedReturnMethodDraft}
        text={text}
        onClick={returnMethodSaved}
      />
    </ModalFooter>
  );
}
