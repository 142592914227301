import * as Sentry from '@sentry/react';

export const reportErrorToSentry = (error: unknown) => {
  let enrichedError: Error;

  if (error instanceof Error) {
    enrichedError = error;
  } else {
    enrichedError = new Error(JSON.stringify(error));
  }

  Sentry.captureException(enrichedError);
};
