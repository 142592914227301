import {
  Price, BusinessUnit, SelectedTimeWindow,
} from 'shared-frontend';
import { RefundSummary } from '../../models/CustomerReturnRequest';
import { SubmitReturnPostMessage } from '../../models/SamsPostMessage';
import { RefundCalcResponse } from '../../models/refundCalcResponse';
import { ReturnSettlementV2Response } from '../../models/CustomerReturnResponse';

type ReturnSuccess = {
  refundSummary: RefundCalcResponse['summary'];
  returnInfo: ReturnInfo,
  returnId: string,
  returnReference?: string,
  pickupDate: string,
  expectedReturnDate: string,
  returnSettlement?: ReturnSettlementV2Response,
};
type ReturnError = {
  returnInfo: ReturnInfo,
  error: string,
  message: string,
};
export type ReturnInfo = {
  isPickUp: boolean;
  price: Price,
  receivingBusinessUnit: BusinessUnit,
  timeWindow: SelectedTimeWindow,
  refund: RefundSummary,
};

export function postSubmitResponse({
  refundSummary,
  returnInfo,
  returnId,
  returnReference,
  pickupDate,
  expectedReturnDate,
  returnSettlement,
}: ReturnSuccess): void;
export function postSubmitResponse({ returnInfo, error, message }: ReturnError): void;
export function postSubmitResponse({
  refundSummary,
  returnInfo,
  returnId,
  returnReference,
  error,
  message,
  pickupDate,
  expectedReturnDate,
  returnSettlement,
}: Partial<ReturnSuccess> & Partial<ReturnError>): void {
  const postMessageApi = window.chrome?.webview?.postMessage;
  if (!postMessageApi || !returnInfo) return;

  const encodeData = (data: SubmitReturnPostMessage) => window.btoa(JSON.stringify(data));
  const samsData: SubmitReturnPostMessage = {
    action: 'CREATE',
    error: '',
    message: '',
    returnData: null,
  };

  if (error) {
    samsData.error = error;
    samsData.message = message || 'No additional information';
  }
  const {
    price, receivingBusinessUnit, refund,
  } = returnInfo;

  const successfulReturn = returnId
    && returnReference
    && price
    && receivingBusinessUnit
    && refundSummary
    && pickupDate
    && expectedReturnDate;

  if (successfulReturn) {
    samsData.returnData = {
      returnId,
      returnReference,
      receivingBusinessUnit: receivingBusinessUnit!,
      collectionServicePrice: price,
      pickUpDate: pickupDate,
      expectedReturnDate,
      refund,
      refundSummary,
      ...(returnSettlement?.returnOption && { returnOption: returnSettlement.returnOption }),
    };
  }

  postMessageApi(encodeData(samsData));
}
