import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import Button from '@ingka/button';
import { useCloseSidebar } from '../../../store/features/sidebar/sidebarSlice';
import { useGetIsValidQuestionnaireDraft } from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useQuestionnaireSaved } from '../../../store/features/standAloneActions/actions';
import { useGetReturnReference } from '../../../store/features/appStateSlice/appStateSlice';

function Wrapper({ children }: { children: React.ReactNode }) {
  return <ModalFooter className="modal-footer--dual-action" renderBorder>{children}</ModalFooter>;
}

export function QuestionnaireFooter() {
  const isValid = useGetIsValidQuestionnaireDraft();
  const returnReference = useGetReturnReference();

  const { t } = useTranslation();
  const close = useCloseSidebar();
  const saveQuestionnaire = useQuestionnaireSaved();

  if (returnReference) {
    return (
      <Wrapper>
        <Button onClick={close} text={t('commonly-reused.done')} type="primary" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Button text={t('commonly-reused.cancel')} onClick={close} />
      <Button
        type="primary"
        disabled={!isValid}
        text={t('commonly-reused.save')}
        onClick={saveQuestionnaire}
      />
    </Wrapper>
  );
}
