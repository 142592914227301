import type { AppEndPointBuilder } from '../apiSlice';
import type { FailedPickupReason, RescheduleSuccess } from '../../../../models';

export type RescheduleReturnParams = {
  countryCode: Uppercase<string>;
  postBody: {
    fromDateTime: string;
    reasonCode?: FailedPickupReason;
    resourcePoolId?: string;
    returnOptionsId: string;
    timeWindowId: string;
    timezone?: string;
    toDateTime: string;
    updatedBy: string;
  };
  returnId: string;
};

const RESCHEDULE = 'reschedule';
const RESCHEDULE_FAILED = 'reschedule-failed';
type RescheduleType = typeof RESCHEDULE | typeof RESCHEDULE_FAILED;

const getBaseURL = (countryCode:string, returnId: string, api: RescheduleType) => (`/v2/${countryCode}/return-views/cw/${returnId}/actions/${api}`);

export const getRescheduleApi = (config: RescheduleReturnParams) => {
  const { countryCode, postBody, returnId } = config;
  const api = postBody.reasonCode ? RESCHEDULE_FAILED : RESCHEDULE;
  return {
    url: getBaseURL(countryCode, returnId, api),
    method: 'POST',
    body: postBody,
  };
};

export const rescheduleReturnBuilder = (builder: AppEndPointBuilder) => builder
  .mutation<RescheduleSuccess, RescheduleReturnParams>({
  extraOptions: { maxRetries: 0 },
  query: ({ countryCode, postBody, returnId }) => {
    const { url, method, body } = getRescheduleApi({ countryCode, postBody, returnId });
    return {
      url,
      method,
      body,
    };
  },
  transformResponse: (response: RescheduleSuccess, _, arg: RescheduleReturnParams) => {
    const {
      timeWindowId,
    } = arg.postBody;

    return {
      ...response,
      rescheduledWithTimeWindowId: timeWindowId,
    };
  },
});
