import { useApplicableActions } from '../useApplicableActions';

const useHasPickupFailed = () => {
  const { actions } = useApplicableActions();
  if (!actions) return false;

  return actions.includes('CANCEL_FAILED') || actions.includes('RE_SCHEDULE_FAILED');
};

export default useHasPickupFailed;
