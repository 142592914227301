const toUtf8 = (string: string) => {
  const array = Uint8Array.from(string, (c) => c.charCodeAt(0));
  return new TextDecoder().decode(array);
};

const fromUtf8 = (string: string) => {
  const utf8Encoded = new TextEncoder().encode(string);
  return String.fromCodePoint(...utf8Encoded);
};

export const base64ToUtf8String = (base64: string) => {
  const binString = atob(base64);
  return toUtf8(binString);
};

export const stringToBase64Utf8 = (string: string) => {
  const binString = fromUtf8(string);
  return btoa(binString);
};
