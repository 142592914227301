import Tooltip from '@ingka/tooltip';
import { useTranslation } from 'react-i18next';

function TooltipWithCondition(
  { children, tooltipText }: { children: React.ReactNode, tooltipText: string | null },
) {
  const { t } = useTranslation();

  return tooltipText ? (
    <Tooltip tooltipText={t(tooltipText)} className="font-normal [&__.tooltip\_\_body]:max-w-[17rem] ">
      {children}
    </Tooltip>
  ) : children;
}
export default TooltipWithCondition;
