import { AvailableReturnMethods, IsomReturnOption } from 'shared-frontend';
import { CustomerReturnItem } from '../../../../models';
import {
  ItemType,
  ReturnMissingArticleItem,
  ReturnArtItem,
  ConditionOfGoods,
  PaidBy,
} from '../../../../models/CustomerReturn';
import { BusinessUnit } from '../../../../models/Sac';
import type { AppEndPointBuilder } from '../apiSlice';
import { getArticleItemType } from '../../../../components/ArticlesReasonCard/ArticlesReasonCardUtils';

type ReturnMethodsApiConfig = {
  countryCode: string,
  postCode: string,
  items: CustomerReturnItem[],
  paidBy: PaidBy,
  businessUnit: BusinessUnit,
};

type ItemsForApi = {
  itemNo: string,
  quantity: number,
  reasonCode?: string,
  subReasonCode?: string,
  itemType: ItemType,
  unit?: string,
  conditionOfGoods?: ConditionOfGoods,
};

const mapProductsForApi = (items: CustomerReturnItem[]): ItemsForApi[] => {
  const bodyItems = items.map(
    (item) => {
      const type = getArticleItemType(item);

      const {
        itemNo,
        quantity,
        itemType,
      } = item;

      if (type === 'SERVICE_ITEM') {
        return {
          itemNo,
          quantity,
          itemType,
        };
      }

      const nonServiceItem = item as ReturnMissingArticleItem | ReturnArtItem;

      return {
        itemNo,
        quantity,
        itemType,
        ...(
          nonServiceItem.returnReason.mainReasonCode
          && { reasonCode: nonServiceItem.returnReason.mainReasonCode }
        ),
        ...(
          nonServiceItem.returnReason.subReasonCode
          && { subReasonCode: nonServiceItem.returnReason.subReasonCode }
        ),
        ...(
          nonServiceItem.unit
          && { unit: nonServiceItem.unit }
        ),
        ...(
          nonServiceItem.conditionOfGoods
          && { conditionOfGoods: nonServiceItem.conditionOfGoods }
        ),
      };
    },
  );

  return bodyItems;
};

export const fetchReturnOptionsApi = (
  countryCode: string,
  postCode: string,
  items: CustomerReturnItem[],
  paidBy: PaidBy,
  businessUnit: BusinessUnit,
) => {
  const body = {
    paidBy,
    businessUnit,
    items: mapProductsForApi(items),
  };

  return ({ url: `/v1/${countryCode}/postalcodes/${postCode}/return-options`, method: 'POST', body });
};

export const returnMethodsBuilder = (builder: AppEndPointBuilder) => builder.query<
AvailableReturnMethods<IsomReturnOption>,
ReturnMethodsApiConfig>({
  query: (config: ReturnMethodsApiConfig) => {
    const {
      countryCode, postCode, items, paidBy, businessUnit,
    } = config;
    const { url, method, body } = fetchReturnOptionsApi(
      countryCode,
      postCode,
      items,
      paidBy,
      businessUnit,
    );
    return {
      url,
      method,
      body,
    };
  },
});
