import * as Sentry from '@sentry/react';

const sentryStoreEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: ({ meta, payload, type }) => {
    if (type.startsWith('api/executeQuery/') || type.startsWith('api/executeMutation/')) {
      return {
        endpoint: meta.arg.endpointName,
        type,
      };
    }

    if (type === 'appState/setShowStockCorrection') {
      return {
        showStockCorrection: payload,
        type,
      };
    }

    if (type === 'prompt/openPromptToPage') {
      return {
        page: payload,
        type,
      };
    }

    if (type === 'selectedReturnMethod/setSelectedReturnMethodDraft') {
      return {
        returnOption: payload.returnOption,
        type,
      };
    }

    if (type === 'sidebar/openSidebarToPage') {
      return {
        page: payload.page,
        type,
      };
    }

    if (type === 'setInitialCreateMode') {
      return {
        sacId: payload.customerReturn?.sacId,
        items: payload.customerReturn?.items,
        type,
      };
    }

    if (
      type.startsWith('api/internalSubscriptions/')
      || type.startsWith('api/config/')
      || type === 'selectedReturnMethod/setQuestionnaireControl'
    ) {
      return null;
    }

    return {
      type,
    };
  },
  attachReduxState: false,
  stateTransformer: () => null,
});

export default sentryStoreEnhancer;
