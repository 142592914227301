import { SidebarHeader } from '../SidebarHeader';
import { useGetArticleInSidebar } from '../../../store/features/OTCSlice/OTCSlice';

export function OTCArticleActionsHeader() {
  const item = useGetArticleInSidebar();

  return (
    <SidebarHeader title={item?.name || ''} />
  );
}
