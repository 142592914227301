import { nanoid } from '@reduxjs/toolkit';
import {
  ReturnTaxDetails, ReturnPrice, SacItemPrice, SacTaxDetails,
} from '../../../models';
import {
  CustomerReturnView,
  GetCustomerReturnResponse,
} from '../../../models/GetCustomerReturnResponse';

export function mapTaxDetails(details: SacTaxDetails[]): ReturnTaxDetails[] {
  return details.map((detail) => {
    const { rate, type, code } = detail;
    return {
      taxType: type,
      taxCode: code,
      taxPercentage: rate,
    };
  });
}
export function mapPriceInfo(price: SacItemPrice): ReturnPrice {
  const {
    amount, totalAmount, currency, includingTax, tax,
  } = price;
  return {
    totalPrice: {
      currencyCode: currency,
      priceInclTax: includingTax ? totalAmount : null,
      priceExclTax: includingTax ? null : totalAmount,
    },
    unitPrice: {
      currencyCode: currency,
      priceInclTax: includingTax ? amount : null,
      priceExclTax: includingTax ? null : amount,
      taxDetails: mapTaxDetails(tax),
    },
  };
}

export function mapSacPriceToReturnPrice({
  price, originalPrice,
}: { price: SacItemPrice, originalPrice: SacItemPrice, }) {
  return {
    price: mapPriceInfo(price),
    originalPrice: mapPriceInfo(originalPrice),
  };
}

export function mapGetReturnResponseItemsToCustomerReturn(
  responseItems: GetCustomerReturnResponse['itemViewModel'],
) {
  return responseItems.map((item) => ({
    ...item,
    ...(!item.id && { id: nanoid() }),
  }));
}

export function mapGetReturnResponseToCustomerReturn(
  returnResponse: GetCustomerReturnResponse,
): CustomerReturnView {
  const {
    preferredCommunicationMethod,
    questionnaire,
    ...filteredReturnCustomer
  } = returnResponse.customer;

  return {
    sacId: returnResponse.sourceReference.value,
    causingBusinessUnit: returnResponse.causingBusinessUnit,
    numberOfLabels: Number(returnResponse.returnAgreement.returnSettlement.numberOfLabels),
    paidBy: returnResponse.returnAgreement.returnSettlement.paidBy,
    isCreditPayment: returnResponse.returnAgreement.returnSettlement.isCreditPayment,
    items: mapGetReturnResponseItemsToCustomerReturn(
      returnResponse.itemViewModel,
    ),
    customer: {
      ...filteredReturnCustomer,
      isBusinessCustomer: Boolean(returnResponse.customer.isBusinessCustomer),
      preferredLanguage: returnResponse.customer.preferredLanguage || '',
    },
    returnAgreement: {
      ...returnResponse.returnAgreement,
    },
    actions: returnResponse.actions,
  };
}
