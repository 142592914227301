import { GetAvailableTimeWindowsApiConfig, GetAvailableTimeWindowsResponse } from '../../../../models';
import type { AppEndPointBuilder } from '../apiSlice';

export const fetchAvailableTimeWindowsApi = (
  {
    countryCode,
    returnReference,
  }: GetAvailableTimeWindowsApiConfig,
) => ({ url: `v1/${countryCode}/returns/${returnReference}/time-windows`, method: 'GET' });

export const getAvailableTimeWindowsBuilder = (builder: AppEndPointBuilder) => builder.query<
GetAvailableTimeWindowsResponse, GetAvailableTimeWindowsApiConfig>({
  query: (config: GetAvailableTimeWindowsApiConfig) => {
    const { countryCode, returnReference } = config;
    const { url, method } = fetchAvailableTimeWindowsApi({ countryCode, returnReference });
    return { url, method };
  },
});
