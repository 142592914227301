import RadioButtonGroup from '@ingka/radio-button-group';
import InlineMessage from '@ingka/inline-message';
import { useTranslation } from 'react-i18next';
import { useGetFailedPickupReason, useSetFailedPickupReason } from '../../store/features/appStateSlice/appStateSlice';
import { FailedPickupApplicableActions } from '../../models';

function PickupFailedRadioGroup({ action }: { action: FailedPickupApplicableActions }) {
  const { t } = useTranslation();
  const setFailedPickupReason = useSetFailedPickupReason();
  const failedPickupReason = useGetFailedPickupReason();
  const translationsRoot = 'prompt-actions.failed-pickup-reason';

  const labels = {
    serviceProvider: t(`${translationsRoot}.serviceProvider`),
    customer: t(`${translationsRoot}.customer`),
    confirmCancel: t(`${translationsRoot}.confirmCancel`),
    confirmReschedule: t(`${translationsRoot}.confirmReschedule`),
    pickupUnsuccessful: t(`${translationsRoot}.pickupUnsuccessful`),
  };

  const options = [
    {
      id: '1',
      label: labels.serviceProvider,
      value: 'FAILED_TSP',
      onChange: () => setFailedPickupReason('FAILED_TSP'),
      checked: failedPickupReason === 'FAILED_TSP',
      name: 'pickupFailedRadios',
    },
    {
      id: '2',
      label: labels.customer,
      value: 'FAILED_CUSTOMER',
      onChange: () => setFailedPickupReason('FAILED_CUSTOMER'),
      checked: failedPickupReason === 'FAILED_CUSTOMER',
      name: 'pickupFailedRadios',
    },
  ];

  const cautionaryMessage: Record<FailedPickupApplicableActions, string> = {
    RE_SCHEDULE_FAILED: labels.confirmReschedule,
    CANCEL_FAILED: labels.confirmCancel,
  };

  return (
    <>
      <RadioButtonGroup
        name={labels.pickupUnsuccessful}
        list={options}
      />
      <InlineMessage
        variant="cautionary"
        body={cautionaryMessage[action]}
        subtle
        className="pt-6"
      />
    </>
  );
}

export default PickupFailedRadioGroup;
