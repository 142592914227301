import InlineMessage from '@ingka/inline-message';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import { Card, RefundAmount } from 'shared-frontend';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { useRefundCalculations } from '../../store/features/api/refundCalc/refundCalcHooks';

export type ReturnMethodData = {
  name: string,
  description: string,
  confirmationInfo: string,
  selectedTime?: string,
  selectedTimeDescription?: string,
};

function Wrapper({ children }: { children: React.ReactNode }) {
  return <Card className="pt-0">{children}</Card>;
}

function RefundSummaryCard(): React.ReactElement {
  const { t } = useTranslation();
  const { countryCode, languageCode } = useMarketLocale();
  const refund = useRefundCalculations();

  if (!refund) {
    return (
      <Wrapper>
        <InlineMessage
          subtle
          variant="negative"
          ssrIcon={informationCircle}
          body={t('refund-summary.noRefundCalcAvailable')}
          className=""
        />
      </Wrapper>
    );
  }

  const refundAmountProps = {
    countryCode,
    languageCode,
    currencyCode: refund.currencyCode,
    refundAmount: refund.subTotal || 0,
    serviceFee: refund.collectionServiceCost || 0,
    estimatedRefund: refund.estimatedRefund || 0,
    totalRefundStrikeThrough: refund.hasExchangeResolution,
    descriptionLabels: {
      purchaseTotal: t('refund-summary.totalValue'),
      serviceFee: t('refund-summary.collectionServiceCost'),
      refundTotal: t('refund-summary.estimatedRefundValue'),
    },
  };

  return (
    <Wrapper>
      <RefundAmount {...refundAmountProps} />
    </Wrapper>
  );
}

export default RefundSummaryCard;
