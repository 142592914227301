import { useTranslation } from 'react-i18next';
import { SidebarHeader } from '../SidebarHeader';
import {
  useCloseSidebar,
  useGoToSidebarPage,
} from '../../../store/features/sidebar/sidebarSlice';
import { useGetReturnReference } from '../../../store/features/appStateSlice/appStateSlice';
import { useCreateRoute } from '../../../hooks/useAppRoutes';

export function TimeWindowPageHeader() {
  const close = useCloseSidebar();
  const { t } = useTranslation();
  const goToSelectReturnMethod = useGoToSidebarPage('return-method');
  const isCreate = useCreateRoute();
  const returnReference = useGetReturnReference();
  const onBackButton = returnReference ? undefined : goToSelectReturnMethod;

  const onClose = () => {
    if (isCreate) {
      goToSelectReturnMethod();
    }
    close();
  };

  return (
    <SidebarHeader
      backBtnClick={onBackButton}
      ariaBackBtnTxt={t('time-windows-sidebar.backBtn')}
      closeBtnClick={onClose}
      title={t('time-windows-sidebar.title')}
    />
  );
}
