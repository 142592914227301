import { Price } from 'shared-frontend';
import { CustomerReturnItem, ReturnPrice } from '../../../../models';
import {
  ItemType,
} from '../../../../models/CustomerReturn';

export type RefundCalcBodyCollectionService = Pick<Price, 'priceExclTax' | 'priceInclTax' | 'taxDetails'> & {
  currencyCode: string;
};

type RefundCalcBodyItemsPrice = Pick<ReturnPrice, 'unitPrice' | 'totalPrice'> & {
  totalPrice: Pick<ReturnPrice['totalPrice'], 'currencyCode' | 'priceExclTax' | 'priceInclTax'>,
  unitPrice: Pick<ReturnPrice['unitPrice'], 'currencyCode' | 'priceExclTax' | 'priceInclTax' | 'taxDetails'>,
};

export type RefundCalcBodyItems = {
  itemNo: string,
  quantity: number,
  itemType: ItemType,
  price: ReturnPrice
  originalPrice: ReturnPrice,
};

export const mapCollectionServicePriceForApi = (price: Price): RefundCalcBodyCollectionService => ({
  currencyCode: price.currencyCode ?? '',
  priceInclTax: price.priceInclTax,
  priceExclTax: price.priceExclTax,
  taxDetails: price.taxDetails?.map((taxDetail) => ({
    taxType: taxDetail.taxType,
    taxCode: taxDetail.taxCode,
    taxAmount: taxDetail.taxAmount,
    taxableAmount: taxDetail.taxableAmount,
    taxPercentage: taxDetail.taxPercentage,
  })),
});

export const mapPriceForApi = (price: ReturnPrice): RefundCalcBodyItemsPrice => ({
  totalPrice: {
    currencyCode: price.totalPrice.currencyCode,
    priceInclTax: price.totalPrice.priceInclTax,
    priceExclTax: price.totalPrice.priceExclTax,
  },
  unitPrice: {
    currencyCode: price.unitPrice.currencyCode,
    priceInclTax: price.unitPrice.priceInclTax,
    priceExclTax: price.unitPrice.priceExclTax,
    taxDetails: price.unitPrice.taxDetails.map((taxDetail) => ({
      taxType: taxDetail.taxType,
      taxCode: taxDetail.taxCode,
      taxPercentage: taxDetail.taxPercentage,
    })),
  },
});

export const mapProductsForApi = (
  items: CustomerReturnItem[],
): RefundCalcBodyItems[] => items.map(
  ({
    itemNo,
    quantity,
    itemType,
    price,
    originalPrice,
  }) => ({
    itemNo,
    quantity,
    itemType,
    price: mapPriceForApi(price),
    originalPrice: mapPriceForApi(originalPrice),
  }),
);
