import { useCancelReturnMutation } from '../../store/features/api/apiSlice';
import {
  useGetFailedPickupReason, useGetReturnId, useGetReturnReference, useGetUserId,
} from '../../store/features/appStateSlice/appStateSlice';
import { useMarketLocale } from '../useMarketLocale';
import { CancelReturnPostMessage } from '../../models/SamsPostMessage';
import { CancelReturnParams } from '../../store/features/api/cancelReturn/cancelReturn';

export const useCancelReturn = () => {
  const { countryCode } = useMarketLocale();
  const returnId = useGetReturnId() as string;
  const returnReference = useGetReturnReference() as string;
  const userId = useGetUserId() as string;
  const [cancelReturnMutation] = useCancelReturnMutation();

  const postMessageApi = window.chrome?.webview?.postMessage;
  const encodeData = (data: CancelReturnPostMessage) => window.btoa(JSON.stringify(data));
  const failedPickupReason = useGetFailedPickupReason();
  const postBody: CancelReturnParams['postBody'] = {
    updatedBy: userId,
    ...(failedPickupReason && { reasonCode: failedPickupReason }),
  };

  const cancelReturn = () => cancelReturnMutation(
    {
      countryCode, postBody, returnId,
    },
  )
    .unwrap()
    .then(() => {
      if (!postMessageApi) return;
      postMessageApi(encodeData({
        action: 'CANCEL',
        error: '',
        message: '',
        returnData: {
          returnId,
          returnReference,
        },
      }));
    })
    .catch((error: any) => {
      if (!postMessageApi) return;
      postMessageApi(encodeData({
        action: 'CANCEL',
        error: 'RETURN_CANCEL_FAILED',
        message: error.data?.message ?? 'Something went wrong, no additional information available',
        returnData: null,
      }));
    });
  return cancelReturn;
};
