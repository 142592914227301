import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import { apiSlice } from '../api/apiSlice';
import {
  InitialCreateModePayload,
  setInitialCreateMode,
  ArticlesWithIncorrectUnit,
  articlesWithIncorrectUnit,
  removePoP,
  removeSelectedArticlesPop,
  removeArticle,
  removeBlindReturnArticle,
} from '../standAloneActions/extraReducersActions';

export type PromptPageKey =
  'confirm-cancel'
  | 'retry-cancel'
  | 'retry-reschedule'
  | 'failed-pickup-cancel'
  | 'failed-pickup-reschedule'
  | 'articles-with-incorrect-unit'
  | 'missing-customer-information'
  | 'otc-remove-pop'
  | 'otc-remove-selected-articles-pop'
  | 'otc-remove-article'
  | 'otc-remove-blind-return-article'
  | null;

export type PromptState = {
  loading: boolean;
  open: boolean;
  page: PromptPageKey;
};

export const initialState: PromptState = {
  loading: false,
  open: false,
  page: null,
};

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    closePrompt: (state, { payload }: PayloadAction<PromptPageKey>) => {
      // we want the payload so other slices can know when we are closing the reschedule retry
      state.loading = false;
      state.open = false;
      state.page = null;
    },
    openPromptToPage: (state, { payload }: PayloadAction<PromptPageKey>) => {
      state.loading = false;
      state.open = true;
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialCreateMode, (
        state: PromptState,
        action: PayloadAction<InitialCreateModePayload>,
      ) => {
        if (!action.payload.customerReturn) return;
        const {
          customerReturn: {
            customer: { billingInfo, deliveryInfo: { email, mobile, phone } },
          },
        } = action.payload;
        if ((!email && !mobile && !phone) || !billingInfo.email) {
          state.page = 'missing-customer-information';
          state.loading = false;
          state.open = true;
        }
      })
      .addCase(articlesWithIncorrectUnit, (
        state: PromptState,
        action: PayloadAction<ArticlesWithIncorrectUnit>,
      ) => {
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.loading = false;
          state.open = true;
          state.page = 'articles-with-incorrect-unit';
        }
      })
      .addCase(removePoP, (state) => {
        state.loading = false;
        state.open = false;
        state.page = null;
      })
      .addCase(removeSelectedArticlesPop, (state) => {
        state.loading = false;
        state.open = false;
        state.page = null;
      })
      .addCase(removeArticle, (state) => {
        state.loading = false;
        state.open = false;
        state.page = null;
      })
      .addCase(removeBlindReturnArticle, (state) => {
        state.loading = false;
        state.open = false;
        state.page = null;
      })
      .addMatcher(apiSlice.endpoints.rescheduleReturn.matchPending, (state) => {
        if (state.open) {
          state.loading = true;
        }
      })
      .addMatcher(apiSlice.endpoints.rescheduleReturn.matchRejected, (state) => {
        state.page = 'retry-reschedule';
        state.loading = false;
        state.open = true;
      })
      .addMatcher(apiSlice.endpoints.rescheduleReturn.matchFulfilled, (state) => {
        state.page = null;
        state.loading = false;
        state.open = false;
      })
      .addMatcher(apiSlice.endpoints.cancelReturn.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(apiSlice.endpoints.cancelReturn.matchRejected, (state) => {
        state.page = 'retry-cancel';
        state.loading = false;
        state.open = true;
      })
      .addMatcher(apiSlice.endpoints.cancelReturn.matchFulfilled, (state) => {
        state.page = null;
        state.loading = false;
        state.open = false;
      });
  },
});

export const useGetPromptLoading = () => useLamnaSelector((state) => state.prompt.loading);
export const useGetPromptOpen = () => useLamnaSelector((state) => state.prompt.open);
export const useGetPromptPage = () => useLamnaSelector((state) => state.prompt.page);

export const {
  closePrompt,
  openPromptToPage,
} = promptSlice.actions;

export const useClosePrompt = () => {
  const page = useGetPromptPage();
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(closePrompt(page));
  };
};

export const useGoToPromptPage = (page: PromptPageKey) => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(openPromptToPage(page));
  };
};
