import SSRIcon from '@ingka/ssr-icon';
import returns from '@ingka/ssr-icon/paths/returns';
import BottomBar from './BottomBar/BottomBar';
import Toast from './Toast/Toast';

export function TopBar() {
  return (
    <div data-testid="top-bar" className="text-brand-yellow bg-coworker-blue px-[31.37px] flex flex-row h-[56px] items-center space-x-7">
      <SSRIcon paths={returns} data-testid="returns-icon" />
      <h1 className="text-brand-yellow text-m font-bold">LÄMNA</h1>
    </div>
  );
}

export function ControlBarHeader({ children }: { children: React.ReactNode }) {
  return (
    <header className="sticky top-0 z-layer3">
      {children}
      <Toast />
    </header>
  );
}

function ControlBar() {
  return (
    <ControlBarHeader>
      <TopBar />
      <BottomBar />
    </ControlBarHeader>

  );
}

export default ControlBar;
