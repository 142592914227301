import { StatusViewModel } from 'shared-frontend/src/modules/self-serve-ui/models';
import type { AppEndPointBuilder } from '../apiSlice';
import { ViewModeApplicableActions, FailedPickupReason } from '../../../../models';

export type CancelReturnParams = {
  countryCode: Uppercase<string>;
  postBody: {
    reasonCode?: FailedPickupReason;
    updatedBy: string;
  };
  returnId: string;
};

export type CancelReturnSuccessResponse = {
  statusViewModel: StatusViewModel,
  actions: ViewModeApplicableActions[]
};

const CANCEL = 'cancel';
const CANCEL_FAILED = 'cancel-failed';
type CancelType = typeof CANCEL | typeof CANCEL_FAILED;

const getBaseURL = (countryCode:string, returnId: string, api: CancelType) => (`/v2/${countryCode}/return-views/cw/${returnId}/actions/${api}`);

export const getCancelApi = (config: CancelReturnParams) => {
  const { countryCode, postBody, returnId } = config;
  const api = postBody.reasonCode ? CANCEL_FAILED : CANCEL;
  return {
    url: getBaseURL(countryCode, returnId, api),
    method: 'POST',
    body: postBody,
  };
};

export const cancelReturnBuilder = (builder: AppEndPointBuilder) => builder
  .mutation<CancelReturnSuccessResponse, CancelReturnParams>({
  extraOptions: { maxRetries: 0 },
  query: ({ countryCode, postBody, returnId }) => {
    const { url, method, body } = getCancelApi({ countryCode, postBody, returnId });
    return {
      body,
      method,
      url,
    };
  },
});
