import { useGetIsAuthenticated } from './store/features/appStateSlice/appStateSlice';
import { useMarketConfigFetch } from './useMarketConfigFetch';
import { isValidatedMarketConfig } from './store/features/api/marketConfig/marketConfig';

interface App {
  children: JSX.Element | JSX.Element[] | null ;
}

export function AuthenticatedApp({ children }: App) {
  const isAuthenticated = useGetIsAuthenticated();
  return isAuthenticated ? children : null;
}

export function MarketConfigFetch({ children }: App) {
  const {
    data: marketConfig,
  } = useMarketConfigFetch();

  return isValidatedMarketConfig(marketConfig)
    ? children
    : null;
}
