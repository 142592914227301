import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Prompt as IngkaPrompt,
} from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import {
  useClosePrompt,
  useGetPromptLoading,
  useGetPromptOpen,
  useGetPromptPage,
} from '../../store/features/prompt/promptSlice';
import { useGetPromptContent } from './useGetPromptContent';
import { useSetFailedPickupReason } from '../../store/features/appStateSlice/appStateSlice';

export function Prompt() {
  const closePrompt = useClosePrompt();
  const isLoading = useGetPromptLoading();
  const isOpen = useGetPromptOpen();
  const { body, footer, title } = useGetPromptContent();
  const page = useGetPromptPage();
  const setFailedPickup = useSetFailedPickupReason();
  const { t } = useTranslation();

  if (!page) return null;

  const handleCloseBtn = () => {
    if (isLoading) return;
    closePrompt();
    setFailedPickup(null);
  };

  return (
    <Modal
      data-testid="prompt"
      escapable={!isLoading}
      handleCloseBtn={handleCloseBtn}
      visible={isOpen}
    >
      <IngkaPrompt
        footer={<ModalFooter>{footer}</ModalFooter>}
        header={(
          <ModalHeader
            closeBtnClick={closePrompt}
            ariaCloseTxt={t('commonly-reused.close')}
          />
)}
        title={title}
        titleId="title-id"
      >
        <ModalBody className="!p-0">
          {body}
        </ModalBody>
      </IngkaPrompt>
    </Modal>
  );
}
