import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { DataCard } from 'shared-frontend';
import { useRefundMethodConfig } from './useRefundMethodConfig';

export type ReturnMethodData = {
  name: string,
  description: string,
  confirmationInfo: string,
  selectedTime?: string,
  selectedTimeDescription?: string,
};

function RefundMethodCard(): React.ReactElement {
  const { t } = useTranslation();
  const { content, config } = useRefundMethodConfig();

  return (
    <DataCard
      className="text-sm mb-12"
      content={content}
      config={config}
      title={
        <Text tagName="h2" headingSize="m">{t('refund-method-card.title')}</Text>
      }
    />
  );
}

export default RefundMethodCard;
