import { Price } from 'shared-frontend';
import {
  CustomerReturnItem,
} from '../../../../models';
import { RefundCalcResponse } from '../../../../models/refundCalcResponse';
import type { AppEndPointBuilder } from '../apiSlice';
import {
  mapCollectionServicePriceForApi,
  mapProductsForApi,
  RefundCalcBodyCollectionService,
  RefundCalcBodyItems,
} from './refundCalcUtils';

type RefundCalcApiConfig = {
  items: CustomerReturnItem[],
  servicePrice: Price,
  countryCode: string,
};

type RefundCalcPayloadContract = {
  items: RefundCalcBodyItems[],
  collectionService: RefundCalcBodyCollectionService,
};

export const fetchReturnOptionsApi = ({
  items,
  servicePrice,
  countryCode,
}: RefundCalcApiConfig) => {
  const body: RefundCalcPayloadContract = {
    items: mapProductsForApi(items),
    collectionService: mapCollectionServicePriceForApi(servicePrice),
  };

  return ({ url: `/v1/${countryCode}/price-calculation/refund-summaries`, method: 'POST', body });
};

export const refundCalcBuilder = (builder: AppEndPointBuilder) => builder.query<
RefundCalcResponse,
RefundCalcApiConfig>({
  query: (config: RefundCalcApiConfig) => {
    const { url, method, body } = fetchReturnOptionsApi(config);
    return {
      url,
      method,
      body,
    };
  },
});
