import {
  useRefundCalcQuery,
} from '../store/features/api/apiSlice';
import { useLamnaSelector } from '../store/app/hooks';
import { useInitialRequestData } from '../components/app/initialFetchUtils';
import { useViewRoute } from './useAppRoutes';
import { CustomerReturnItem } from '../models';

export const useRefundCalcResponse = () => {
  const isViewMode = useViewRoute();
  const { items, countryCode } = useInitialRequestData();
  const selectedReturnMethod = useLamnaSelector(
    (state) => state.selectedReturnMethod.selectedReturnMethod,
  );
  const servicePrice = selectedReturnMethod?.price;

  return useRefundCalcQuery(
    {
      items: items as CustomerReturnItem[],
      servicePrice: servicePrice!,
      countryCode: countryCode!,
    },
    {
      skip: items.length === 0
        || servicePrice === undefined
        || countryCode === null
        || isViewMode,
    },
  );
};
