import { getCountryAndLanguageFromUrl } from './i18n.js';

export type Config = {
  VITE_GCP_PROJECT: string,
  VITE_MARKET_CONFIG_VERSION: number,
  VITE_LOCAL_RUN: boolean,
  VITE_RETURNS_URL: string,
  VITE_LAMNA_AUDIENCE: string,
  VITE_ENTRA_AUDIENCE: string,
  CDN_PATH: string
};

const getDynamicCdnPath = () => {
  if (import.meta.env.PROD.toString() === 'true') {
    const [countryCode, countryLanguage] = getCountryAndLanguageFromUrl();
    return `/${countryCode}/${countryLanguage}/returns/`;
  }
  return '/';
};

async function getConfig(): Promise<Config> {
  const cdnPath = getDynamicCdnPath();

  const configFileName = 'config.json';
  let url = `${cdnPath}${configFileName}`;
  if (import.meta.env.NODE_ENV === 'test') {
    // We need to have absolute paths in our tests for msw package
    url = `https://${configFileName}`;
  }

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Fetch "ok" was false');
    }

    const json: Config = { ...await response.json(), CDN_PATH: cdnPath };
    return json;
  } catch (err) {
    const enrichedError = new Error(`failed to fetch config file: ${err}`);
    throw enrichedError;
  }
}

let fetchedConfig: Config;
const config = () => new Promise<Config>((resolve, reject) => {
  if (fetchedConfig) {
    resolve(fetchedConfig);
    return;
  }

  getConfig()
    .then((json) => {
      fetchedConfig = json;
      resolve(json);
    })
    .catch((error) => reject(error));
});

export default config;
