import type { AppEndPointBuilder } from '../apiSlice';

export type ReasonCodes = {
  code: string;
  id: string;
  subReasons: Array<{
    availableInSelfService: boolean;
    code: string;
    id: string;
    isClaim?: boolean;
    titleTranslationId: string;
  }>
};

export type CustomerComment = {
  commentEnabled: boolean;
  validateWith: string;
};

type ReturnReasons = {
  customerComment?: CustomerComment;
  reasonCodes?: Array<ReasonCodes>;
};

export interface MarketConfig {
  proofOfPurchase: {
    dateFormat: string;
  },
  returnReasons: ReturnReasons,
  customerInformation: any,
  termsAndConditions: any,
  returnOptions: any,
  refundOptions: any,
  features: { enabled?: [], test?: [] },
  purchaseInformation: any,
}

export interface ValidatedMarketConfig extends Omit<MarketConfig, 'returnReasons'> {
  returnReasons: Required<ReturnReasons>
}

export type MarketConfigApiConfig = {
  countryCode: Lowercase<string>,
};

export function isValidatedMarketConfig(marketConfig: any): marketConfig is ValidatedMarketConfig {
  const configReasonCodes = marketConfig?.returnReasons.reasonCodes;

  return configReasonCodes?.length > 0;
}

export const fetchMarketConfigApi = ({ countryCode }: MarketConfigApiConfig) => (`/v1/${countryCode}/market-config`);

export const marketConfigBuilder = (
  builder: AppEndPointBuilder,
) => builder.query<MarketConfig, MarketConfigApiConfig>({
  query: (config: MarketConfigApiConfig) => fetchMarketConfigApi(config),
});
