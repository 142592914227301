import { getEnabledFeatures } from 'shared-frontend';
import { Config } from '../../config';
import { Sac } from '../../models';
import { store } from '../../store/app/store';
import { MarketConfig } from '../../store/features/api/marketConfig/marketConfig';
import { setInitialCreateMode } from '../../store/features/standAloneActions/extraReducersActions';
import { reportErrorToSentry } from '../reportErrorToSentry';
import { toCustomerReturn, validateSacForCustomerReturn } from '../sacUtils';
import { MarketConfigError, handleMarketConfigError, setupErrorMessage } from './handleSetupError';
import { STATUS, validateJWT } from '../tokenUtils';
import { base64ToUtf8String } from '../base64utils';

const decodeAndParseSac = (encodedString: string) => {
  const sac: Sac = JSON.parse(base64ToUtf8String(encodedString));
  return sac;
};

export const getSanitizedSac = (sac: Sac): Omit<Sac, 'auth'> => {
  const { auth, ...sacWithoutAuth } = sac;
  return sacWithoutAuth;
};

export const createInitReturnFunc = (cfg: Config, marketConfig: MarketConfig | any) => function initReturn(encodedSac: string = '') {
  try {
    const sac = decodeAndParseSac(encodedSac);

    handleMarketConfigError({ marketConfig, sacId: sac.sacId });

    window.getSanitizedSac = () => getSanitizedSac(sac);
    const validateJwtResult = validateJWT(sac.auth.accessToken, cfg);
    if (validateJwtResult === STATUS.INVALID) {
      return validateJwtResult;
    }

    const validateSacContent = validateSacForCustomerReturn(sac, marketConfig as MarketConfig);
    if (!validateSacContent.isValid) {
      const { customer, auth, ...sentrySacContent } = sac;
      const sentryErrorMessage = JSON.stringify({
        message: validateSacContent.message,
        sac: sentrySacContent,
      });
      const sentryError = new Error(sentryErrorMessage);
      sentryError.name = 'INVALID_SAC_CONTENT';
      reportErrorToSentry(sentryError);

      return {
        error: 'INVALID_SAC_CONTENT',
        message: validateSacContent.message,
      };
    }
    const enabledFeatures = marketConfig?.features
      ? getEnabledFeatures(marketConfig?.features)
      : [];
    store.dispatch(setInitialCreateMode({
      accessToken: sac.auth.accessToken,
      customerReturn: toCustomerReturn(sac, enabledFeatures),
      isAuthenticated: true,
      userId: sac.auth.userId,
    }));

    return STATUS.VALID;
  } catch (e) {
    const enrichedError = (e instanceof MarketConfigError) ? e : setupErrorMessage(e);
    reportErrorToSentry(enrichedError);
    return enrichedError;
  }
};
