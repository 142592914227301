import Skeleton from '@ingka/skeleton';
import { useTranslation } from 'react-i18next';

export function SkeletonItem() {
  return <Skeleton className="mb-4 h-12" />;
}

export function EntriesSkeleton() {
  const { t } = useTranslation();
  return (
    <div role="progressbar" aria-busy aria-label={t('commonly-reused.loading')}>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </div>
  );
}
